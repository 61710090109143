import React, { Component } from "react";
import { message, Button } from "antd";
import PageLayout from "../layout/base";
import LoginAccount from "../auth/login";
import RegisterAccount from "../auth/registration";
import "../../../assets/css/login.css";

class LoginIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      userName: "admin",
      userPassword: "",
      visiblePage: "LOGIN",
    };
  }

  componentDidMount() {
    document.title = "Connexion Utilisateur";
  }

  onRequestRegister() {
    this.setState({ visiblePage: "REGISTER" });
  }

  onRequestLogin() {
    this.setState({ visiblePage: "LOGIN" });
  }

  onRequestPassword() {
    this.props.history.push("/password/recover");
  }

  onLogged(payload) {
    console.log("payload:", payload);
    message.success("Auhtentification réussie !!");
    this.props.history.push("/account/profile");
  }

  render() {
    const { visiblePage } = this.state;
    return (
      <PageLayout>
        <main id={"content"} role={"main"} className={"site-wrapper-reveal"}>
          <section
            className={"bg-cover min-vh-100"}
            style={{
              backgroundColor: "#E7EBEF",
            }}
          >
            <div className="d-flex justify-content-center">
              <div className="page-content">
                <div className="login-card">
                  {/* Form */}
                  {visiblePage == "LOGIN" && (
                    <LoginAccount
                      onAccountLogged={(payload) => this.onLogged(payload)}
                      onRequestRegister={() => this.onRequestRegister()}
                      onRequestPassword={() => this.onRequestPassword()}
                    />
                  )}
                  {visiblePage == "REGISTER" && (
                    <RegisterAccount
                      onAccountCreated={(payload) => this.onLogged(payload)}
                      onRequestLogin={() => this.onRequestLogin()}
                    />
                  )}
                </div>
              </div>
              {/* / .row */}
            </div>
            {/* / .container */}
          </section>
        </main>
      </PageLayout>
    );
  }
}

export default LoginIndex;
