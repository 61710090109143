import axios from "axios";
import { API_BASE_DEV, API_BASE_PROD } from "../constants/api";
import { ORGANIZATION_ID } from "../constants/app";
import { getCookie } from "../helpers/cookies";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
    }

    return Promise.reject(error.response);
  }
);

const ApiContext = axios.create({
  baseURL: API_BASE_PROD,
  withCredentials: false,
  timeout: 30000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Organization-ID": ORGANIZATION_ID,
  },
});

/**
 * Check Registration Invitation
 * @param {*} params
 */

export function checkRegistrationInvite(params = {}) {
  params["access_token"] = sessionStorage.getItem("accessToken");
  return ApiContext.get(`candidate-api/invitation/check`, {
    params,
  });
}

export function confirmRegistrationInvite(bodyParams, params = {}) {
  params["access_token"] = sessionStorage.getItem("accessToken");
  return ApiContext.post(`candidate-api/invitation/confirm`, bodyParams, {
    params,
  });
}

/**
 * JOBS
 */

export function getApplications(params = {}) {
  params["access_token"] = sessionStorage.getItem("accessToken");
  return ApiContext.get(`public-api/list`, {
    params,
  });
}

export function getData(id, params = {}) {
  params["access_token"] = sessionStorage.getItem("accessToken");
  return ApiContext.get(`public-api/detail/${id}`, {
    params,
  });
}

export function doRequest(bodyParams, params = {}) {
  params["access_token"] = sessionStorage.getItem("accessToken");
  return ApiContext.post(`public-api/apply`, bodyParams, { params });
}

export function authAccount(bodyParams, params = {}) {
  return ApiContext.post(`candidate-api/login`, bodyParams, { params });
}

export function passwordRecover(bodyParams, params = {}) {
  return ApiContext.post(`candidate-api/password/recover`, bodyParams, {
    params,
  });
}

export function passwordRecoverVerify(bodyParams, params = {}) {
  return ApiContext.post(`candidate-api/password/verify`, bodyParams, {
    params,
  });
}

export function passwordRecoverReset(bodyParams, params = {}) {
  return ApiContext.post(`candidate-api/password/reset`, bodyParams, {
    params,
  });
}

export function createAccount(bodyParams, params = {}) {
  return ApiContext.post(`candidate-api/registration/new`, bodyParams, {
    params,
  });
}

export function activateAccount(bodyParams) {
  return ApiContext.post(`candidate-api/registration/activate`, bodyParams);
}

export function activateResend(bodyParams) {
  return ApiContext.post(`candidate-api/registration/mail`, bodyParams);
}

export function updateEntity(bodyParams, params = {}) {
  params["access_token"] = sessionStorage.getItem("accessToken");
  return ApiContext.post(`candidate-api/entity/update`, bodyParams, { params });
}

export function deleteEntity(bodyParams, params = {}) {
  params["access_token"] = sessionStorage.getItem("accessToken");
  return ApiContext.post(`candidate-api/entity/refresh`, bodyParams, {
    params,
  });
}

export function updateProfile(bodyParams, params = {}) {
  params["access_token"] = sessionStorage.getItem("accessToken");
  return ApiContext.post(`candidate-api/profile/update`, bodyParams, {
    params,
  });
}

export function updatePassword(bodyParams, params = {}) {
  params["access_token"] = sessionStorage.getItem("accessToken");
  return ApiContext.post(`candidate-api/profile/password`, bodyParams, {
    params,
  });
}

export function uploadResume(bodyParams, params = {}) {
  params["access_token"] = sessionStorage.getItem("accessToken");
  return ApiContext.post(`candidate-api/resume/upload`, bodyParams, {
    params,
    headers: { "Content-Type": "application/form-data" },
  });
}

export function getDocuments(params = {}) {
  params["access_token"] = sessionStorage.getItem("accessToken");
  return ApiContext.get(`candidate-api/resume`, { params });
}
