import React, { Component } from "react";
import moment from "moment";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Drawer,
  message,
  DatePicker,
  Modal,
  Space,
} from "antd";
import { isMobile } from "react-device-detect";
import queryString from "query-string";
import SlidingPane from "react-sliding-pane";
import {
  singleJob,
  getUsersDomains,
  getEducationDomains,
  getEducationLevels,
  getCompanySectors,
  sendApplication,
} from "../../api/jobService";
import {
  getEntityCache,
  deleteEntityCache,
  updateUserFields,
} from "../../helpers/cache";
import { deleteEntity } from "../../api/candidateService";
import { userConnected } from "../../utils/token";
import { COUNTIRES_DATA } from "../../data/countriesList";
import PageLayout from "../layout/base";
import EducationModal from "../resume/eudcationModal";
import ExperienceModal from "../resume/experienceModal";
import RegisterAccount from "../auth/registration";
import LoginAccount from "../auth/login";
import ResumeFile from "./file";

class ApplyResume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobId: null,
      jobContent: null,
      jobData: null,
      resumeFile: null,
      isLoading: true,
      loadingDomains: true,
      modalEducationOpen: false,
      modalExperienceOpen: false,
      modalRegisterOpen: false,
      modalLoginOpen: false,
      domainsItems: [],
      educationDomainsItems: [],
      companySectorsItems: [],
      educationLevels: [],

      educationsList: getEntityCache(),
      experiencesList: getEntityCache("experience"),

      selectedEducation: null,
      selectedExperience: null,
      selectedEntityID: null,
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    let parsed = queryString.parse(window.location.search);
    setTimeout(() => this.autoFill(), 2000);
    if (parsed.id) {
      this.setState({ jobId: parsed.id }, () => {
        this.apiFetchData();
      });
    }
  }

  autoFill() {
    if (!userConnected()) return;

    let json = sessionStorage.getItem("userFields");
    if (!json) return;
    let userFields = JSON.parse(json);
    const { softwares_and_office, languages } = userFields;

    this.formRef.current.setFieldsValue({
      candidate_city: userFields.address_city,
      candidate_country: userFields.address_country,
      candidate_email: userFields.email_address,
      candidate_name: userFields.family_name,
      candidate_firstname: userFields.first_name,
      candidate_lastname: userFields.last_name,
      candidate_phone: userFields.phone_numbers,
      candidate_sex: userFields.gender_sex,
      candidate_address_line: userFields.address_line1,
      candidate_city: userFields.address_line2,

      candidate_skill_excel: softwares_and_office.excel_skill,
      candidate_skill_powerpoint: softwares_and_office.powerpoint_skill,
      candidate_skill_word: softwares_and_office.word_skill,
      candidate_skill_others: softwares_and_office.others_softwares_skill,
    });

    if (userFields.primary_domain_id != "") {
      let candidate_domains = `${userFields.primary_domain_id}`.split(",");
      this.formRef.current.setFieldsValue({ candidate_domains });
    }

    if (userFields.birth_date != "") {
      let candidate_birthdate = moment(userFields.birth_date);
      this.formRef.current.setFieldsValue({ candidate_birthdate });
    }

    if (languages.languages_local != null) {
      let candidate_languages = `${languages.languages_local}`.split(",");
      this.formRef.current.setFieldsValue({ candidate_languages });
    }

    if (languages.languages_international != null) {
      let candidate_languages_intl = `${languages.languages_international}`.split(
        ","
      );
      this.formRef.current.setFieldsValue({ candidate_languages_intl });
    }
  }

  apiFetchData() {
    const { jobId } = this.state;
    singleJob(jobId, { t: Date.now() })
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.id) {
          this.setState({ jobContent: response, jobData: response.data });
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });

    getUsersDomains().then((result) => {
      this.setState({ loadingDomains: false });

      let response = result.data;
      if (response.data) {
        this.setState({ domainsItems: response.data });
      } else {
        throw new Error("error");
      }
    });

    getEducationDomains().then((result) => {
      this.setState({ loadingDomains: false });

      let response = result.data;
      if (response.data) {
        this.setState({ educationDomainsItems: response.data });
      } else {
        throw new Error("error");
      }
    });

    getEducationLevels().then((result) => {
      this.setState({ loadingDomains: false });

      let response = result.data;
      if (response.data) {
        this.setState({ educationLevels: response.data });
      } else {
        throw new Error("error");
      }
    });

    getCompanySectors().then((result) => {
      this.setState({ loadingDomains: false });

      let response = result.data;
      if (response.data) {
        this.setState({ companySectorsItems: response.data });
      } else {
        throw new Error("error");
      }
    });
  }

  onFormSubmit(payload, resourceId = null) {
    console.log("onFormSubmit: ", payload);
    this.setState({
      modalEducationOpen: false,
      selectedEducation: null,
      selectedEntityID: null,
    });

    let educationsList = getEntityCache();
    this.setState({ educationsList });
  }

  onFormSubmit1(payload, resourceId = null) {
    console.log("onFormSubmit1: ", payload);
    this.setState({
      modalExperienceOpen: false,
      selectedExperience: null,
      selectedEntityID: null,
    });

    let experiencesList = getEntityCache("experience");
    this.setState({ experiencesList });
  }

  handleDeleteEducation(rowId) {
    let bodyParams = new URLSearchParams();
    bodyParams.append("entity_id", rowId);

    this.setState({ isLoading: true });
    deleteEntity(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.deleted) {
          message.success("Element supprimé avec succès");
          // delete entity from cache
          deleteEntityCache(rowId);
          let educationsList = getEntityCache();
          this.setState({ educationsList });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Erreur survenue lors de la suppression");
      });
  }

  handleDeleteExperience(rowId) {
    let bodyParams = new URLSearchParams();
    bodyParams.append("entity_id", rowId);

    this.setState({ isLoading: true });
    deleteEntity(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.deleted) {
          message.success("Element supprimé avec succès");
          // delete entity from cache
          deleteEntityCache(rowId);
          let experiencesList = getEntityCache("experience");
          this.setState({ experiencesList });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Erreur survenue lors de la suppression");
      });
  }

  handleEditEducation(rowEntity) {
    this.setState({
      modalEducationOpen: true,
      selectedEducation: rowEntity,
      selectedEntityID: rowEntity.id,
    });
  }

  handleEditExperience(rowEntity) {
    this.setState({
      modalExperienceOpen: true,
      selectedExperience: rowEntity,
      selectedEntityID: rowEntity.id,
    });
  }

  /**
   * onGotFile
   * handle after retrieved resume file
   */
  onGotFile(resumeFile) {
    console.log({ resumeFile });
    this.setState({ resumeFile });
  }

  /**
   * onClearFile
   * handle after user clear resume file
   */
  onClearFile() {
    console.log({ resumeFile: null });
  }

  onFinish = (values) => {
    console.log("onFinish: ", values);

    const { educationsList, experiencesList, jobId, resumeFile } = this.state;
    const {
      candidate_city,
      candidate_country,
      candidate_address_line,
      candidate_domains,
      candidate_email,
      candidate_firstname,
      candidate_name,
      candidate_languages,
      candidate_languages_intl,
      candidate_lastname,
      candidate_phone,
      candidate_skill_excel,
      candidate_skill_others,
      candidate_skill_powerpoint,
      candidate_skill_word,
      candidate_sex,
      candidate_birthdate,
      estimated_salary,
    } = values;

    let profileData = sessionStorage.getItem("userFields");
    let userFields = JSON.parse(profileData);

    if (educationsList.length == 0) {
      message.error(
        "Veuillez ajouter au moins un Parcours scolaire / Universitaire"
      );
      return;
    }

    if (resumeFile == null) {
      message.error("Veuillez joindre un CV");
      return;
    }

    let bodyParams = new URLSearchParams();
    bodyParams.append("job_reference", jobId);
    bodyParams.append("candidate_resume", resumeFile.file);
    bodyParams.append("candidate_reference", userFields.id);
    bodyParams.append("candidate_name", candidate_name);
    bodyParams.append("candidate_firstname", candidate_firstname);
    bodyParams.append("candidate_lastname", candidate_lastname);
    bodyParams.append("candidate_email", candidate_email);
    bodyParams.append("candidate_phone", candidate_phone);
    bodyParams.append("candidate_sex", candidate_sex);
    bodyParams.append("estimated_salary", estimated_salary);
    bodyParams.append(
      "candidate_birthdate",
      candidate_birthdate.format("YYYY-MM-DD")
    );

    bodyParams.append(
      "candidate_languages",
      Array.from(candidate_languages).join(",")
    );
    bodyParams.append(
      "candidate_languages_intl",
      Array.from(candidate_languages_intl).join(",")
    );
    bodyParams.append(
      "candidate_domains",
      Array.from(candidate_domains).join(",")
    );

    bodyParams.append("candidate_skill_others", candidate_skill_others);
    bodyParams.append("candidate_skill_excel", candidate_skill_excel);
    bodyParams.append("candidate_skill_powerpoint", candidate_skill_powerpoint);
    bodyParams.append("candidate_skill_word", candidate_skill_word);

    bodyParams.append("candidate_country", candidate_country);
    bodyParams.append("candidate_city", candidate_city);
    bodyParams.append("candidate_address_line", candidate_address_line);

    bodyParams.append("educations_list", JSON.stringify(educationsList));
    bodyParams.append("experiences_list", JSON.stringify(experiencesList));

    this.setState({ isLoading: true });
    sendApplication(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.application_id) {
          updateUserFields(values);
          Modal.success({
            title: "Reussi!",
            okText: "Fermer",
            content:
              "Votre candidature a été envoyée, vous recevrez dans un bref délai les avancées sur la présélection de votre dossier",
            onOk: () => {
              this.props.history.push("/");
            },
            onCancel: () => {
              this.props.history.push("/");
            },
          });
        } else if (response.error_type) {
          let errors = {};
          errors["already_applied"] = "Vous avez déjà postulé à cette offre";
          errors["error_exception"] =
            "Erreur survenue lors de l'envoie de votre candidature";
          message.error(errors[response.error_type]);
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        Modal.error({
          title: "Echec",
          content: "Erreur survenue lors de l'envoie de votre candidature",
          okText: "Fermer",
          onOk: () => {},
        });
      });
  };

  /**
   * onAccountCreated
   * handle after user created account from drawer modal
   */

  onAccountCreated = (payload) => {
    console.log("payload:", payload);
    this.setState({ modalRegisterOpen: false });

    message.success("Compte crée avec succès !");
    setTimeout(() => {
      window.location.reload();
    }, 800);
  };

  /**
   * onAccountLogged
   * handle after user created account from drawer modal
   */

  onAccountLogged = (payload) => {
    console.log("payload:", payload);
    this.setState({ modalLoginOpen: false });

    message.success("Connecté avec succès !");
    setTimeout(() => {
      window.location.reload();
    }, 800);
  };

  render() {
    const {
      jobContent,
      jobData,
      isLoading,
      modalEducationOpen,
      modalExperienceOpen,
      modalRegisterOpen,
      modalLoginOpen,
      educationLevels,
      educationDomainsItems,
      domainsItems,
      companySectorsItems,
      selectedEducation,
      selectedExperience,
      selectedEntityID,
    } = this.state;
    console.log("jobData", jobData);

    const isConnected = userConnected();
    return (
      <PageLayout>
        <Spin spinning={isLoading}>
          <section className="inner-header-page">
            <div className="container">
              <div className="col-md-8">
                <div className="left-side-container">
                  <div className="freelance-image">
                    <a href="#">
                      <img
                        src={require("../../../assets/img/logos/default.jpg")}
                        className="img-responsive"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="header-details">
                    <h4>Postuler à l'offre</h4>
                    <p>Formulaire de candidature</p>
                    <ul>
                      <li>
                        <a href="single-company-profile.html">
                          <i className="fa fa-user" />{" "}
                          {jobContent && jobContent.available_position} Poste(s)
                        </a>
                      </li>
                      <li>
                        <div className="verified-action">Vérifié</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 bl-1 br-gary">
                <div className="right-side-detail">
                  {jobContent && (
                    <ul>
                      <li>
                        <span className="detail-info">Durée</span>
                        {jobContent.contrat_duration}
                      </li>
                      <li>
                        <span className="detail-info">Experience</span>
                        {`${jobData.experience_years} ans`}
                      </li>
                      <li>
                        <span className="detail-info">Emplacement</span>
                        {jobContent.locations}
                      </li>
                    </ul>
                  )}

                  <ul className="social-info">
                    <li>
                      <a
                        href="https://www.facebook.com/itmafrica/"
                        target={"_blank"}
                      >
                        <i className="fe fe-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/ItmRdc" target={"_blank"}>
                        <i className="fe fe-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/itm-africa-7526a6162/detail/recent-activity/"
                        target={"_blank"}
                      >
                        <i className="fe fe-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UC9b7Hg1wrog88Qv7PIeDeng"
                        target={"_blank"}
                      >
                        <i className="fe fe-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {jobData && (
            <section>
              <div className="container">{this.columnLeft()}</div>
            </section>
          )}
        </Spin>

        <SlidingPane
          isOpen={modalEducationOpen}
          title="Diplôme / Brevet"
          width={isMobile ? "100%" : "60%"}
          onRequestClose={() => {
            this.setState({
              modalEducationOpen: false,
              selectedEducation: null,
              selectedEntityID: null,
            });
          }}
        >
          <EducationModal
            resourceId={selectedEntityID}
            entityParam={selectedEducation}
            professionalDomains={domainsItems}
            educationLevels={educationLevels}
            educationDomains={educationDomainsItems}
            onFormSubmit={(payload, index) => this.onFormSubmit(payload, index)}
          />
        </SlidingPane>

        <SlidingPane
          width={isMobile ? "100%" : "60%"}
          isOpen={modalExperienceOpen}
          title="Parcours professionnel"
          onRequestClose={() => {
            this.setState({
              modalExperienceOpen: false,
              selectedExperience: null,
              selectedEntityID: null,
            });
          }}
        >
          <ExperienceModal
            resourceId={selectedEntityID}
            entityParam={selectedExperience}
            departementsList={domainsItems}
            companySectors={companySectorsItems}
            onFormSubmit1={(payload, index) =>
              this.onFormSubmit1(payload, index)
            }
          />
        </SlidingPane>

        {/** Authentication slides panel */}
        {/** <Drawer
          width={700}
          visible={modalLoginOpen}
          title="Connexion Utilisateur"
          placement="right"
          onClose={() => {
            this.setState({ modalLoginOpen: false });
          }}
          footer={<div></div>}
        >
          <LoginAccount
            onAccountLogged={this.onAccountLogged}
            onRequestClose={() => {
              this.setState({ modalLoginOpen: false });
            }}
          />
        </Drawer> */}

        <SlidingPane
          width={isMobile ? "100%" : "40%"}
          isOpen={modalLoginOpen}
          title="Connexion utilisateur"
          onRequestClose={() => {
            this.setState({ modalLoginOpen: false });
          }}
        >
          <LoginAccount
            onAccountLogged={this.onAccountLogged}
            onRequestClose={() => {
              this.setState({ modalLoginOpen: false });
            }}
          />
        </SlidingPane>

        <SlidingPane
          width={isMobile ? "100%" : "40%"}
          isOpen={modalRegisterOpen}
          title="Créer un compte"
          onRequestClose={() => {
            this.setState({ modalRegisterOpen: false });
          }}
        >
          <RegisterAccount
            onAccountCreated={this.onAccountCreated}
            onRequestClose={() => {
              this.setState({ modalRegisterOpen: false });
            }}
          />
        </SlidingPane>
      </PageLayout>
    );
  }

  columnLeft() {
    const {
      loadingDomains,
      domainsItems,
      jobContent,
      educationsList,
      experiencesList,
    } = this.state;
    const isConnected = userConnected();
    const columnsTable1 = [
      {
        title: "Début",
        dataIndex: "date_debut_month",
        key: "date_debut_month",
        width: "15%",
        render: (text, record) => (
          <span className="font-weight-bold">
            {record.date_debut_month}/{record.date_debut_year}
          </span>
        ),
      },
      {
        title: "Institut / Univ.",
        dataIndex: "education_institute",
        key: "name",
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: "Domaine",
        dataIndex: "education_domain_text",
        key: "name",
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        width: "15%",
        render: (text, record, index) => (
          <Space>
            <Button onClick={() => this.handleEditEducation(record)}>
              Modifier.
            </Button>
            <Button danger onClick={() => this.handleDeleteEducation(text)}>
              Supprimer
            </Button>
          </Space>
        ),
      },
    ];
    const columnsTable2 = [
      {
        title: "Début",
        dataIndex: "date_debut_month",
        key: "date_debut_month",
        width: "15%",
        render: (text, record) => (
          <span className="font-weight-bold">
            {record.date_debut_month}/{record.date_debut_year}
          </span>
        ),
      },
      {
        title: "Entreprise/Société",
        dataIndex: "experience_company_name",
        key: "name",
        width: "12%",
      },
      {
        title: "Departement",
        dataIndex: "experience_sector_text",
        key: "name",
        width: "17%",
      },
      {
        title: "Poste",
        dataIndex: "experience_position",
        key: "experience_position",
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        width: "15%",
        render: (text, record, index) => (
          <Space>
            <Button onClick={() => this.handleEditExperience(record)}>
              Modifier.
            </Button>
            <Button danger onClick={() => this.handleDeleteExperience(text)}>
              Supprimer
            </Button>
          </Space>
        ),
      },
    ];
    return (
      <div className="col-md-10 col-sm-12">
        {!userConnected() && (
          <div className="container-detail-box">
            <h3 className="font-weight-bold">Connectez-vous à votre compte</h3>
            <h5>Afin de récupérer votre CV</h5>
            <Divider />
            <Space>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => this.setState({ modalLoginOpen: true })}
              >
                Se Connecter
              </button>
              <button
                type={"button"}
                className="btn btn-dark"
                onClick={() => this.setState({ modalRegisterOpen: true })}
              >
                Créer un compte
              </button>
            </Space>
          </div>
        )}

        {isConnected && (
          <div className="container-detail-box">
            <div className="apply-job-header">
              <h4>{jobContent.title}</h4>
              <a href="#!" className="cl-success">
                <span>
                  <i className="fe fe-globe" />
                  ITM
                </span>
              </a>
              <span>
                <i className="fe fe-map-pin" />
                {jobContent.locations}
              </span>
            </div>

            <div>
              <Form
                ref={this.formRef}
                layout="vertical"
                onFinish={this.onFinish}
              >
                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_name"}
                      label={"Nom de famille"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_lastname"}
                      label={"Post Nom"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_firstname"}
                      label={"Prenom"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_phone"}
                      label={"Téléphone"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_country"}
                      label={"Pays de résidence"}
                      initialValue={"CD"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                      >
                        {COUNTIRES_DATA.map((item, index) => (
                          <Select.Option value={item.code}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_city"}
                      label={"Ville"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={"candidate_address_line"}
                      label={"Adresse de résidence"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_email"}
                      label={"Adresse email"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item
                          name={"candidate_sex"}
                          label={"Sexe"}
                          rules={[
                            {
                              required: true,
                              message: "Veuillez compléter ce champs!",
                            },
                          ]}
                        >
                          <Select
                            size={"large"}
                            placeholder={"Séléctionner"}
                            style={{ width: "100%" }}
                          >
                            <Select.Option value={"MALE"}>Homme</Select.Option>
                            <Select.Option value={"FEMALE"}>
                              Femme
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={"candidate_birthdate"}
                          label={"Date de naissance"}
                          rules={[
                            {
                              required: true,
                              message: "Veuillez compléter ce champs!",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={"DD/MM/YYYY"}
                            size={"large"}
                            placeholder={"Séléctionner date"}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name={"candidate_domains"}
                      label={"Domaine(s) de Métier"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        loading={loadingDomains}
                        size={"large"}
                        mode={"multiple"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                        showArrow={true}
                        showSearch={true}
                        onSearch={(val) => console.log("onSearch:", val)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {domainsItems.map((item, index) => (
                          <Select.Option key={index} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={"estimated_salary"}
                      label={"Estimation salariale (en Dollars US)"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        placeholder={"Séléctionner"}
                        style={{ width: "100%" }}
                      >
                        <Select.Option value={"0_300"}>
                          Moins de 300$
                        </Select.Option>
                        <Select.Option value={"300_500"}>
                          entre 300$ et 500$
                        </Select.Option>
                        <Select.Option value={"500_750"}>
                          entre 500$ et 750$
                        </Select.Option>
                        <Select.Option value={"750_1000"}>
                          entre 750$ et 1000$
                        </Select.Option>
                        <Select.Option value={"1000_1500"}>
                          entre 1000$ et 1500$
                        </Select.Option>
                        <Select.Option value={"1500_2000"}>
                          Plus de 1500$
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h4>Diplôme / Brevet</h4>
                  </div>
                  <Button
                    onClick={() => {
                      if (!isConnected) {
                        this.setState({ modalLoginOpen: true });
                      } else {
                        this.setState({ modalEducationOpen: true });
                      }
                    }}
                  >
                    Ajouter un diplôme
                  </Button>
                </div>
                <Table
                  style={{ marginTop: 10, marginBottom: 20 }}
                  dataSource={educationsList}
                  columns={columnsTable1}
                />
                <Divider />
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h4>Expérience(s) professionnelle(s)</h4>
                  </div>
                  <Button
                    onClick={() => {
                      if (!isConnected) {
                        this.setState({ modalLoginOpen: true });
                      } else {
                        this.setState({ modalExperienceOpen: true });
                      }
                    }}
                  >
                    Ajouter une expérience
                  </Button>
                </div>
                <Table
                  style={{ marginTop: 10, marginBottom: 20 }}
                  dataSource={experiencesList}
                  columns={columnsTable2}
                />
                <Divider />
                <h4>Langues et Autres Compétences</h4>
                <Row gutter={10}>
                  <Col span={24}>
                    <Form.Item
                      name={"candidate_languages_intl"}
                      label={"Langues Internationnales"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        mode={"multiple"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                      >
                        <Select.Option value={"Français"}>
                          Français
                        </Select.Option>
                        <Select.Option value={"Anglais"}>Anglais</Select.Option>
                        <Select.Option value={"Italien"}>Italien</Select.Option>
                        <Select.Option value={"Allemand"}>
                          Allemand
                        </Select.Option>
                        <Select.Option value={"Portugais"}>
                          Portugais
                        </Select.Option>
                        <Select.Option value={"Mandarin"}>
                          Mandarin
                        </Select.Option>
                        <Select.Option value={"Russe"}>Russe</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={"candidate_languages"}
                      label={"Langues Locales"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        mode={"multiple"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                      >
                        <Select.Option value={"Lingala"}>Lingala</Select.Option>
                        <Select.Option value={"Tshiluba"}>
                          Tshiluba
                        </Select.Option>
                        <Select.Option value={"Kikongo"}>Kikongo</Select.Option>
                        <Select.Option value={"Swahili"}>Swahili</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={"candidate_skill_word"}
                      label={"Maitrise Word"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                      >
                        <Select.Option value={"AUCUN"}>AUCUN</Select.Option>
                        <Select.Option value={"DEBUTANT"}>
                          DEBUTANT
                        </Select.Option>
                        <Select.Option value={"MAITRISE"}>
                          MAITRISE
                        </Select.Option>
                        <Select.Option value={"AVANCE"}>AVANCE</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={"candidate_skill_excel"}
                      label={"Maitrise Excel"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                      >
                        <Select.Option value={"AUCUN"}>AUCUN</Select.Option>
                        <Select.Option value={"DEBUTANT"}>
                          DEBUTANT
                        </Select.Option>
                        <Select.Option value={"MAITRISE"}>
                          MAITRISE
                        </Select.Option>
                        <Select.Option value={"AVANCE"}>AVANCE</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={"candidate_skill_powerpoint"}
                      label={"Maitrise PowerPoint"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                      >
                        <Select.Option value={"AUCUN"}>AUCUN</Select.Option>
                        <Select.Option value={"DEBUTANT"}>
                          DEBUTANT
                        </Select.Option>
                        <Select.Option value={"MAITRISE"}>
                          MAITRISE
                        </Select.Option>
                        <Select.Option value={"AVANCE"}>AVANCE</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name={"candidate_skill_others"}
                      label={"Autres logiciels maitrisés"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input.TextArea rows={10} size={"large"} />
                    </Form.Item>
                  </Col>
                </Row>
                <h4>Votre CV (document en attache)</h4>
                <ResumeFile
                  onGotFile={(file) => this.onGotFile(file)}
                  onClearFile={() => this.onClearFile()}
                />
                <Divider />
                <button
                  type={"button"}
                  className="btn btn-success"
                  onClick={() => {
                    this.formRef.current.submit({});
                  }}
                >
                  Postuler à cette offre
                </button>
              </Form>
            </div>
          </div>
        )}
        <div className="job-detail-statistic flex-middle-sm">
          <div className="statistic-item flex-middle">
            <div className="icon text-theme">
              <i className="fe fe-phone-call theme-cl" />
            </div>
            <span className="text">(+243) 82 85 02 119</span>
          </div>
          <div className="statistic-item flex-middle">
            <div className="icon text-theme">
              <i className="fe fe-mail theme-cl" />
            </div>
            <span className="text">contact@itmafrica.com</span>
          </div>
          <div className="statistic-item flex-middle">
            <div className="icon text-theme">
              <i className="fe fe-linkedin theme-cl" />
            </div>
            <span className="text">itmafrica</span>
          </div>
        </div>
        {/* Similar Jobs */}
      </div>
    );
  }
}

export default ApplyResume;
