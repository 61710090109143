import React, { Component } from "react";
import { Col, Divider, Form, Input, message, Row, Space, Spin } from "antd";
import PageLayout from "../layout/base";
import { updatePassword } from "../../api/candidateService";

class AccountPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: "Utilisateur",
      isLoading: false,
    };
  }

  componentDidMount() {
    document.title = "Mot de passe";
    this.getProfile();
  }

  getProfile() {
    const data = sessionStorage.getItem("userFields");
    if (data) {
      let userFields = JSON.parse(data);
      if (userFields.id) this.setState({ fullName: userFields.display_name });
    }
  }

  onFinish = (values) => {
    const { password_new, password_new_confirm, password_current } = values;

    if (password_new != password_new_confirm) {
      message.error("Les mots de passe ne correspondent pas!");
      return;
    }

    let bodyParams = new URLSearchParams();
    bodyParams.append("password_new", password_new);
    bodyParams.append("password_current", password_current);

    this.setState({ isLoading: true });
    updatePassword(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });
        let response = result.data;
        if (response.updated) {
          message.success("Mot de passe modifié avec succès !");
          this.props.history.push("/account/profile");
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error(
          "Une erreur s'est produite lors du traitement de votre demande"
        );
      });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <PageLayout>
        <section className="accordion">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="container-detail-box">
                  <h1 className="font-weight-bold">
                    Modifier votre mot de passe
                  </h1>
                  <Divider />

                  <Spin spinning={isLoading}>
                    <Form layout={"vertical"} onFinish={this.onFinish}>
                      <Row gutter={12}>
                        <Col span={24}>
                          <Form.Item
                            label={"Actuel mot de passe"}
                            name={"password_current"}
                            rules={[
                              {
                                required: true,
                                message: "Veuillez compléter ce champs!",
                              },
                            ]}
                          >
                            <Input.Password size={"large"} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label={"Nouveau mot de passe"}
                            name={"password_new"}
                            rules={[
                              {
                                required: true,
                                message: "Veuillez compléter ce champs!",
                              },
                            ]}
                          >
                            <Input.Password size={"large"} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label={"Confirmer votre mot de passe"}
                            name={"password_new_confirm"}
                            rules={[
                              {
                                required: true,
                                message: "Veuillez compléter ce champs!",
                              },
                            ]}
                          >
                            <Input.Password size={"large"} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />

                      <div className="d-flex justify-content-between">
                        <button
                          type={"submit"}
                          className="btn btn-success font-weight-bold"
                        >
                          Changer le mot de passe
                        </button>
                      </div>
                    </Form>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </section>
      </PageLayout>
    );
  }

  handleLogout() {
    sessionStorage.clear();
    this.props.history.push("/account/signin");
  }
}

export default AccountPassword;
