import React, { Component } from "react";
import { Divider, message, Upload, Spin, Button } from "antd";
import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import PageLayout from "../layout/base";
import { uploadResume, getDocuments } from "../../api/candidateService";
import { extractFilename } from "../../utils/strings";
const { Dragger } = Upload;

class ResumeFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentsList: [],
      fileList: [],
      uploadFile: null,
      isLoading: false,
      showForm: false,
    };
  }

  componentDidMount() {
    document.title = "Uploader votre CV";
    setTimeout(() => this.apiFetch(), 500);
  }

  apiFetch() {
    getDocuments()
      .then((result) => {
        let response = result.data;
        if (response.items) {
          let documentsList = Array.from(response.items);
          this.setState({ documentsList }, () => this.onGotFile());
          if (documentsList.length == 0) {
            this.setState({ showForm: true });
          }
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {});
  }

  clearList() {
    const { onClearFile } = this.props;
    this.setState({ documentsList: [], showForm: true });
    if (onClearFile) onClearFile();
  }

  uploadProps() {
    return {
      name: "file",
      multiple: true,
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      beforeUpload: (file, fileList) => this.beforeUpload(file, fileList),
      onChange(info) {
        const { status } = info.file;
        if (status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
  }

  beforeUpload(file, fileList) {
    this.setState({ fileList, uploadFile: file });

    return false;
  }

  onFinish = () => {
    const { uploadFile, fileList } = this.state;
    console.log({ uploadFile });

    if (uploadFile == null && fileList.length == 0) {
      message.error("Veuillez choisir un fichier");
      return;
    }

    let bodyParams = new FormData();
    bodyParams.append("upload_file", uploadFile);

    this.setState({ isLoading: true });
    uploadResume(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });
        let response = result.data;
        if (response.uploaded) {
          message.success("CV uploadé avec succès !");

          // update list
          let uploadedDocument = response.uploaded_document;
          this.setState(
            { documentsList: [uploadedDocument], showForm: false },
            () => this.onGotFile()
          );
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error(
          "Une erreur s'est produite lors du traitement de votre demande"
        );
      });
  };

  onGotFile() {
    const { documentsList } = this.state;
    if (documentsList.length == 0) return;

    let gotFile = documentsList[0];
    const { onGotFile } = this.props;
    if (onGotFile) onGotFile(gotFile);
  }

  render() {
    const { isLoading, fileList, documentsList, showForm } = this.state;
    const props = this.uploadProps();
    return (
      <div className="row">
        <div className="col-12 pl-2 pr-2">
          <div className="mb-2">
            {documentsList.length > 0 && (
              <>
                <Divider />
                <ul className="">
                  {documentsList.map((item, index) => (
                    <li key={index}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className={"d-flex"}>
                          <i
                            className="mr-2 fe fe-file-text"
                            style={{ fontSize: 40 }}
                          />
                          <div>
                            <h5 className="mb-0">Mon CV</h5>
                            <div>
                              <a href={item.file} target={"_blank"}>
                                {extractFilename(item.file)}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div>
                          <a href={item.file} target={"_blank"}>
                            <i
                              className="mr-2 fe fe-download"
                              style={{ fontSize: 20 }}
                            />
                          </a>
                          <a
                            href={"javascript:void(0);"}
                            onClick={() => this.clearList()}
                          >
                            <i
                              className="mr-2 fe fe-x"
                              style={{ fontSize: 20 }}
                            />
                          </a>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}

            {showForm && (
              <div>
                <Divider />
                <Spin spinning={isLoading}>
                  <div>
                    <Upload
                      {...props}
                      accept={".pdf,.doc,.docx"}
                      fileList={fileList}
                      multiple={false}
                    >
                      <Button icon={<UploadOutlined />}>
                        Uploader votre document
                      </Button>
                    </Upload>

                    <div className="d-flex justify-content-between mt-4">
                      <Button
                        icon={<SaveOutlined />}
                        type={"primary"}
                        onClick={() => this.onFinish()}
                      >
                        Enregistrer le Document
                      </Button>
                    </div>
                  </div>
                </Spin>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ResumeFile;
