import React, { Component } from "react";
import { message, Button } from "antd";
import "../../../assets/css/404.css";

class Error404 extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.title = "Erreur 404 | Page non trouvée";
  }

  render() {
    return (
      <section
        className={"bg-cover min-vh-100"}
        style={{
          backgroundColor: "#E7EBEF",
        }}
      >
        <div className="d-flex justify-content-center">
          <div className="page-content">
            <div className="login-card">
              {/* Heading */}
              <h1 className="mb-0 display-4 font-weight-bold">Erreur 404</h1>
              <h5 className="text-muted">
                La page que vous recherchez n'existe pas !
              </h5>
              {/* Text */}

              {/* Form */}
              <a href={"/"} className="btn btn-primary btn-block btn-lg mt-5">
                Retour
              </a>
            </div>
          </div>
          {/* / .row */}
        </div>
        {/* / .container */}
      </section>
    );
  }
}

export default Error404;
