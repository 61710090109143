import React, { Component } from "react";
import { Button, Col, Divider, Form, Input, message, Row, Select } from "antd";
import countriesList from "../../data/countries";
import moment from "moment";
import { updateEntity } from "../../api/candidateService";
import { saveToEntityCache } from "../../helpers/cache";

class ExperienceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      yearsList: [],
      resourceID: null,
      endYearVisible: false,
      educationType: "academic",
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    let yearsList = [];
    var firstYear = moment().format("YYYY");

    for (var i = firstYear; i >= 1950; i--) {
      yearsList.push(i);
    }

    this.setState({ yearsList });
  }

  static getDerivedStateFromProps(props, state) {
    const { resourceId, entityParam } = props;
    if (entityParam) {
      if (resourceId != state.resourceID) {
        return { resourceID: resourceId };
      }
    }
    console.log("props", props);
    console.log("state", state);
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { entityParam } = this.props;
    if (entityParam) {
      this.formRef.current.setFieldsValue({
        contact_email_address: entityParam.contact_email_address,
        contact_fullname: entityParam.contact_fullname,
        contact_phone_number: entityParam.contact_phone_number,
        date_debut_month: entityParam.date_debut_month,
        date_debut_year: entityParam.date_debut_year,
        date_end_month: entityParam.date_end_month,
        date_end_year: entityParam.date_end_year,
        experience_city: entityParam.experience_city,
        experience_company_name: entityParam.experience_company_name,
        experience_country: entityParam.experience_country,
        experience_department: entityParam.experience_department,
        experience_position: entityParam.experience_position,
        experience_sector: entityParam.experience_sector,
        experience_tasks: entityParam.experience_tasks,
      });
    }
  }

  onFinish = (values) => {
    console.log("values: ", values);
    const { companySectors, departementsList } = this.props;
    const { resourceID } = this.state;
    const {
      contact_email_address,
      contact_fullname,
      contact_phone_number,
      experience_city,
      experience_company_name,
      experience_country,
      experience_department,
      experience_position,
      experience_sector,
      experience_tasks,
      date_debut_month,
      date_debut_year,
      date_end_month,
      date_end_year,
    } = values;

    let data = {
      contact_email_address,
      contact_fullname,
      contact_phone_number,
      experience_city,
      experience_company_name,
      experience_country,
      experience_department,
      experience_position,
      experience_sector,
      experience_tasks,
      date_debut_month,
      date_debut_year,
      date_end_month,
      date_end_year,
    };

    let entity = departementsList.find((e) => e.id == experience_department);
    data["experience_department_text"] = entity.name;

    let entity1 = companySectors.find((e) => e.id == experience_sector);
    data["experience_sector_text"] = entity1.name;

    if (date_end_month && date_end_year) {
      data["date_end_month"] = date_end_month;
      data["date_end_year"] = date_end_year;
    }
    if (resourceID) data["id"] = resourceID;

    this.updateData(data);
  };

  updateData = (data) => {
    console.log("updateData:", data);
    const { onFormSubmit1 } = this.props;
    const { resourceID } = this.state;

    let bodyParams = new URLSearchParams();
    bodyParams.append("entity_type", "experience");
    bodyParams.append("entity_data", JSON.stringify(data));
    if (resourceID) bodyParams.append("entity_id", resourceID);

    this.setState({ isLoading: true });
    updateEntity(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.created) {
          const { payloads } = response;
          saveToEntityCache(payloads);

          if (onFormSubmit1) onFormSubmit1(data);
          this.formRef.current.resetFields();
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        console.log("error:", err);
        this.setState({ isLoading: false });
        message.error("Impossible d'enregistrer vos informations");
      });
  };

  render() {
    const { yearsList, endYearVisible, educationType, isLoading } = this.state;
    const { companySectors, departementsList } = this.props;
    return (
      <div>
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          layout="vertical"
          initialValues={{ experience_type: educationType }}
        >
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                name={"experience_sector"}
                label={"Secteur d'activité"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Select
                  size={"large"}
                  placeholder={"Séléctionner dans la liste"}
                  showSearch={true}
                  onSearch={(val) => console.log("onSearch:", val)}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                >
                  {companySectors.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"experience_department"}
                label={"Département"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Select
                  size={"large"}
                  placeholder={"Séléctionner dans la liste"}
                  showSearch={true}
                  onSearch={(val) => console.log("onSearch:", val)}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%" }}
                >
                  {departementsList.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"experience_company_name"}
                label={"Nom de l'entreprise"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name={"experience_position"}
                label={"Poste occupé"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"experience_tasks"}
                label={"Responsabilité"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input.TextArea rows={5} size={"large"} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={"experience_country"}
                label={"Pays"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Select
                  size={"large"}
                  placeholder={"Séléctionner dans la liste"}
                  style={{ width: "100%" }}
                  showSearch={true}
                >
                  {countriesList.map((item, index) => (
                    <Select.Option value={item.label} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"experience_city"}
                label={"Ville"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={"date_debut_month"}
                label={"Mois début"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Select
                  size={"large"}
                  placeholder={"Séléctionner dans la liste"}
                  style={{ width: "100%" }}
                >
                  <Select.Option value={"01"}>Janvier</Select.Option>
                  <Select.Option value={"02"}>Février</Select.Option>
                  <Select.Option value={"03"}>Mars</Select.Option>
                  <Select.Option value={"04"}>Avril</Select.Option>
                  <Select.Option value={"05"}>Mai</Select.Option>
                  <Select.Option value={"06"}>Juin</Select.Option>
                  <Select.Option value={"07"}>Juillet</Select.Option>
                  <Select.Option value={"08"}>Aôut</Select.Option>
                  <Select.Option value={"09"}>Septembre</Select.Option>
                  <Select.Option value={"10"}>Octobre</Select.Option>
                  <Select.Option value={"11"}>Novembre</Select.Option>
                  <Select.Option value={"12"}>Décembre</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"date_debut_year"}
                label={"Année début"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Select
                  size={"large"}
                  placeholder={"Séléctionner dans la liste"}
                  style={{ width: "100%" }}
                >
                  {yearsList.map((item, index) => (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"date_end_month"} label={"Mois Fin"}>
                <Select
                  size={"large"}
                  placeholder={"Séléctionner dans la liste"}
                  style={{ width: "100%" }}
                >
                  <Select.Option value={"01"}>Janvier</Select.Option>
                  <Select.Option value={"02"}>Février</Select.Option>
                  <Select.Option value={"03"}>Mars</Select.Option>
                  <Select.Option value={"04"}>Avril</Select.Option>
                  <Select.Option value={"05"}>Mai</Select.Option>
                  <Select.Option value={"06"}>Juin</Select.Option>
                  <Select.Option value={"07"}>Juillet</Select.Option>
                  <Select.Option value={"08"}>Aôut</Select.Option>
                  <Select.Option value={"09"}>Septembre</Select.Option>
                  <Select.Option value={"10"}>Octobre</Select.Option>
                  <Select.Option value={"11"}>Novembre</Select.Option>
                  <Select.Option value={"12"}>Décembre</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"date_end_year"} label={"Année Fin"}>
                <Select
                  size={"large"}
                  placeholder={"Séléctionner dans la liste"}
                  style={{ width: "100%" }}
                >
                  {yearsList.map((item, index) => (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <h5>Réference (contact)</h5>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={"contact_fullname"}
                label={"Nom du contact"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={"contact_phone_number"}
                label={"Numéro de Téléphone du contact"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={"contact_email_address"}
                label={"Adresse email du contact"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Button
            type={"primary"}
            htmlType={"submit"}
            size="large"
            loading={isLoading}
          >
            Enregistrer vos données
          </Button>
        </Form>
      </div>
    );
  }
}

ExperienceModal.defaultProps = {
  resourceId: null,
  companySectors: [],
  departementsList: [],
};

export default ExperienceModal;
