import React, { Component } from "react";
import moment from "moment";
import { Divider, Spin, message, Modal, Space } from "antd";
import { isMobile } from "react-device-detect";
import queryString from "query-string";
import SlidingPane from "react-sliding-pane";
import {
  singleJob,
  getUsersDomains,
  getEducationDomains,
  getEducationLevels,
  getCompanySectors,
  sendApplication,
} from "../../api/jobService";
import {
  getEntityCache,
  deleteEntityCache,
  updateUserFields,
} from "../../helpers/cache";
import { deleteEntity } from "../../api/candidateService";
import { userConnected } from "../../utils/token";
import { COUNTIRES_DATA } from "../../data/countriesList";
import PageLayout from "../layout/base";
import EducationModal from "../resume/eudcationModal";
import ExperienceModal from "../resume/experienceModal";
import RegisterAccount from "../auth/registration";
import LoginAccount from "../auth/login";
import { Link } from "react-router-dom";
import "../../../assets/css/apply.css";

class ApplyIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobId: null,
      jobContent: null,
      jobData: null,
      isLoading: true,
      loadingDomains: true,
      modalEducationOpen: false,
      modalExperienceOpen: false,
      modalRegisterOpen: false,
      modalLoginOpen: false,
      domainsItems: [],
      educationDomainsItems: [],
      companySectorsItems: [],
      educationLevels: [],

      educationsList: getEntityCache(),
      experiencesList: getEntityCache("experience"),

      selectedEducation: null,
      selectedExperience: null,
      selectedEntityID: null,
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    document.title = "Postuler à l'offre";
    let parsed = queryString.parse(window.location.search);
    if (parsed.id) {
      this.setState({ jobId: parsed.id }, () => {
        this.apiFetchData();
      });
    }
  }

  apiFetchData() {
    const { jobId } = this.state;
    singleJob(jobId, { t: Date.now() })
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.id) {
          this.setState({ jobContent: response, jobData: response.data });
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  /**
   * onAccountCreated
   * handle after user created account from drawer modal
   */

  onAccountCreated = (payload) => {
    console.log("payload:", payload);
    this.setState({ modalRegisterOpen: false });

    message.success("Compte crée avec succès !");
    setTimeout(() => {
      window.location.reload();
    }, 800);
  };

  /**
   * onAccountLogged
   * handle after user created account from drawer modal
   */

  onAccountLogged = (payload) => {
    console.log("payload:", payload);
    this.setState({ modalLoginOpen: false });

    message.success("Connecté avec succès !");
    setTimeout(() => {
      window.location.reload();
    }, 800);
  };

  render() {
    const {
      jobContent,
      jobData,
      isLoading,
      modalEducationOpen,
      modalExperienceOpen,
      modalRegisterOpen,
      modalLoginOpen,
      educationLevels,
      educationDomainsItems,
      domainsItems,
      companySectorsItems,
      selectedEducation,
      selectedExperience,
      selectedEntityID,
    } = this.state;
    console.log("jobData", jobData);

    const isConnected = userConnected();
    return (
      <PageLayout>
        <Spin spinning={isLoading}>
          {jobData && (
            <section>
              <div className="container">{this.pageContainer()}</div>
            </section>
          )}
        </Spin>

        <SlidingPane
          isOpen={modalEducationOpen}
          title="Diplôme / Brevet"
          width={isMobile ? "100%" : "60%"}
          onRequestClose={() => {
            this.setState({
              modalEducationOpen: false,
              selectedEducation: null,
              selectedEntityID: null,
            });
          }}
        >
          <EducationModal
            resourceId={selectedEntityID}
            entityParam={selectedEducation}
            professionalDomains={domainsItems}
            educationLevels={educationLevels}
            educationDomains={educationDomainsItems}
            onFormSubmit={(payload, index) => this.onFormSubmit(payload, index)}
          />
        </SlidingPane>

        <SlidingPane
          width={isMobile ? "100%" : "60%"}
          isOpen={modalExperienceOpen}
          title="Parcours professionnel"
          onRequestClose={() => {
            this.setState({
              modalExperienceOpen: false,
              selectedExperience: null,
              selectedEntityID: null,
            });
          }}
        >
          <ExperienceModal
            resourceId={selectedEntityID}
            entityParam={selectedExperience}
            departementsList={domainsItems}
            companySectors={companySectorsItems}
            onFormSubmit1={(payload, index) =>
              this.onFormSubmit1(payload, index)
            }
          />
        </SlidingPane>

        {/** Authentication slides panel */}
        {/** <Drawer
          width={700}
          visible={modalLoginOpen}
          title="Connexion Utilisateur"
          placement="right"
          onClose={() => {
            this.setState({ modalLoginOpen: false });
          }}
          footer={<div></div>}
        >
          <LoginAccount
            onAccountLogged={this.onAccountLogged}
            onRequestClose={() => {
              this.setState({ modalLoginOpen: false });
            }}
          />
        </Drawer> */}

        <SlidingPane
          width={isMobile ? "100%" : "40%"}
          isOpen={modalLoginOpen}
          title="Connexion utilisateur"
          onRequestClose={() => {
            this.setState({ modalLoginOpen: false });
          }}
        >
          <LoginAccount
            onAccountLogged={this.onAccountLogged}
            onRequestClose={() => {
              this.setState({ modalLoginOpen: false });
            }}
          />
        </SlidingPane>

        <SlidingPane
          width={isMobile ? "100%" : "40%"}
          isOpen={modalRegisterOpen}
          title="Créer un compte"
          onRequestClose={() => {
            this.setState({ modalRegisterOpen: false });
          }}
        >
          <RegisterAccount
            onAccountCreated={this.onAccountCreated}
            onRequestClose={() => {
              this.setState({ modalRegisterOpen: false });
            }}
          />
        </SlidingPane>
      </PageLayout>
    );
  }

  pageContainer() {
    const { jobId, jobContent } = this.state;
    const isConnected = userConnected();
    return (
      <div className="col-md-10 col-sm-12">
        {!userConnected() && (
          <div className="container-detail-box">
            <h3 className="font-weight-bold">Connectez-vous à votre compte</h3>
            <h5>Afin de récupérer votre CV</h5>
            <Divider />
            <Space>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => this.setState({ modalLoginOpen: true })}
              >
                Se Connecter
              </button>
              <button
                type={"button"}
                className="btn btn-dark"
                onClick={() => this.setState({ modalRegisterOpen: true })}
              >
                Créer un compte
              </button>
            </Space>
          </div>
        )}

        {isConnected && (
          <div className="container-detail-box">
            <div className="apply-job-header">
              <h2 className={"font-weight-bold display-2"}>
                Comment souhaitiez-vous postuler à l'offre {jobContent.title} ?
              </h2>
            </div>

            <div>
              <ul style={{ paddingInlineStart: 0 }}>
                <li>
                  <Link
                    to={`/apply/resume?id=${jobId}`}
                    className="apply-link"
                  >
                    <span className="apply-link-text">
                      Postuler avec votre dossier en ligne
                    </span>
                    <span className="fe fe-file-text"></span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/apply/resume-file?id=${jobId}`}
                    className="apply-link"
                  >
                    <span className="apply-link-text">Postuler avec un CV en attache</span>
                    <span className="fe fe-upload"></span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}
        <div className="job-detail-statistic flex-middle-sm">
          <div className="statistic-item flex-middle">
            <div className="icon text-theme">
              <i className="fe fe-phone-call theme-cl" />
            </div>
            <span className="text">(+243) 82 85 02 119</span>
          </div>
          <div className="statistic-item flex-middle">
            <div className="icon text-theme">
              <i className="fe fe-mail theme-cl" />
            </div>
            <span className="text">contact@itmafrica.com</span>
          </div>
          <div className="statistic-item flex-middle">
            <div className="icon text-theme">
              <i className="fe fe-linkedin theme-cl" />
            </div>
            <span className="text">itmafrica</span>
          </div>
        </div>
        {/* Similar Jobs */}
      </div>
    );
  }
}

export default ApplyIndex;
