import logo from "./logo.svg";
import "./App.css";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/fonts.css";
import "./assets/css/fontawesome-all.min.css";
import "./assets/css/helper.css";
import "./assets/css/animate-text.css";
import "./assets/css/animate.min.css";
import "./assets/css/progress.css";
import "./assets/css/plugins.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/home-sidebar.css";

import "./assets/css/animate.min.css";
import "./assets/css/fontawesome-all.min.css";

import "react-sliding-pane/dist/react-sliding-pane.css";

import { BrowserRouter } from "react-router-dom";
import RoutesIndex from "./app/routes";

function App() {
  return (
    <div className="App">
      <BrowserRouter basename={"/"}>
        <RoutesIndex />
      </BrowserRouter>
    </div>
  );
}

export default App;
