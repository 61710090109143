import React, { Component } from "react";
import { getCookie } from "../../helpers/cookies";
import { userConnected } from "../../utils/token";
import "../../../assets/css/header.css";

class HomeHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const isAuthenticated = userConnected();
    return (
      <header className="main-header header-style-four">
        {/* Header Upper */}
        <div className="header-upper" style={{ padding: "25px 0" }}>
          <div className="auto-container">
            <div className="clearfix d-flex align-items-center">
              <div className="upper-left clearfix">
                <div className="d-flex info-box">
                  <div className="icon1-box">
                    <span className="fe fe-phone" />
                  </div>
                  <ul
                    style={{
                      overflow: "hidden",
                      paddingLeft: 10,
                    }}
                  >
                    <li>
                      <strong>Appelez-nous</strong>
                    </li>
                    <li>
                      <a href="tel:+243828502119">
                        <span className="phone_numbers3">
                          (+243) 828 502 119
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="text-center flex-fill">
                <a href="https://itmafrica.com/" target={"_blank"}>
                  <img
                    src="https://itmafrica.com/images/logo.png"
                    style={{ width: "130px" }}
                    title="Accueil"
                  />
                </a>
              </div>
              <div className="text-right flags-bar">
                <ul className="nav justify-content-end mt-2">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#"
                      target="_blank"
                      title="RD Congo"
                    >
                      <img
                        src="https://itmafrica.com/images/countries/itm_country_1562076979.png"
                        className="top-flag"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Header Upper */}
        <div className="header-lower" style={{ backgroundColor: "#284a98" }}>
          <div className="auto-container clearfix">
            <div className="nav-outer clearfix">
              {/* Main Menu */}
              <style
                type="text/css"
                dangerouslySetInnerHTML={{
                  __html:
                    "\n     .main-menu .navigation > li > a.active {color: #70e6de;}\n",
                }}
              />
              <nav className="main-menu navbar-expand-md navbar-light">
                <div
                  className="collapse navbar-collapse clearfix"
                  id="navbarSupportedContent"
                >
                  <ul className="navigation clearfix">
                    <li
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      <a
                        href="https://itmafrica.com/a-propos-de-nous"
                        title="A propos de nous"
                        target={"_blank"}
                      >
                        A propos
                      </a>
                    </li>
                    <li
                      style={{
                        marginRight: "15px",
                        textTransform: "uppercase",
                      }}
                      className="dropdown"
                    >
                      <a
                        href="https://itmafrica.com/services"
                        target={"_blank"}
                      >
                        Services
                      </a>
                      <ul>
                        <li>
                          <a
                            href="https://itmafrica.com/services/ressources-humaines"
                            title="Solutions en Gestion des Ressources Humaines"
                            target={"_blank"}
                          >
                            Solutions en Gestion des Ressources Humaines
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://itmafrica.com/services/ventes-et-distribution"
                            title="Solutions en Ventes et Distribution"
                            target={"_blank"}
                          >
                            Solutions en Ventes et Distribution
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://itmafrica.com/services/chaine-d-approvisionnement"
                            title="Solutions en chaîne d'approvisionnement"
                          >
                            Solutions en chaîne d'approvisionnement
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://itmafrica.com/services/secteur-industriel"
                            title="Solutions liées au secteur de l’industrie"
                            target={"_blank"}
                          >
                            Solutions liées au secteur de l’industrie
                          </a>
                        </li>
                      </ul>
                      <div className="dropdown-btn">
                        <span className="fa fa-angle-down" />
                      </div>
                    </li>
                    <li
                      style={{
                        marginRight: "15px",
                        textTransform: "uppercase",
                      }}
                    >
                      <a href="/" className="active">
                        Offres d'emploi
                      </a>
                    </li>
                    <li
                      style={{
                        marginRight: "15px",
                        textTransform: "uppercase",
                      }}
                    >
                      <a
                        href="https://itmafrica.com/actualites"
                        target={"_blank"}
                      >
                        Actualités
                      </a>
                    </li>
                    <li
                      style={{
                        marginRight: "15px",
                        textTransform: "uppercase",
                      }}
                    >
                      <a
                        href="https://itmafrica.com/formations"
                        target={"_blank"}
                      >
                        Formations
                      </a>
                    </li>
                    <li
                      style={{
                        marginRight: "15px",
                        textTransform: "uppercase",
                      }}
                    >
                      <a
                        href="https://itmafrica.com/nous-contacter"
                        target={"_blank"}
                      >
                        Contacts
                      </a>
                    </li>
                    <li
                      style={{
                        marginRight: "15px",
                        textTransform: "uppercase",
                      }}
                    >
                      <a href="https://itmafrica.com/medias" target={"_blank"}>
                        Médias
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div className="outer-box clearfix">
                {/* Button Box */}
                <div className="btn-box">
                  <a
                    href="https://itmafrica.com/e-learning"
                    className="theme-btn btn-style-two btn-rounded"
                    target={"_blank"}
                    style={{
                      fontSize: "12px",
                      padding: "5px 15px",
                    }}
                  >
                    E-Learning
                  </a>
                  {!isAuthenticated && (
                    <a
                      href="/account/signin"
                      className="theme-btn btn-style-one btn-rounded"
                      style={{
                        fontSize: "12px",
                        padding: "5px 15px",
                        marginRight: "5px",
                      }}
                    >
                      Connexion
                    </a>
                  )}
                  {isAuthenticated && (
                    <a
                      href="/account/profile"
                      style={{
                        fontSize: "12px",
                        padding: "5px 15px",
                        marginRight: "5px",
                      }}
                      className="theme-btn btn-style-one btn-rounded"
                    >
                      Mon compte
                    </a>
                  )}
                </div>
              </div>{" "}
              {/* Main Menu End*/}
            </div>
          </div>
        </div>
        {/*End Header Upper*/}
      </header>
    );
  }
}

export default HomeHeader;
