import React, { Component } from "react";
import { Col, Divider, Form, Input, Spin, Row, message } from "antd";
import { passwordRecover as _passwordRecover } from "../../api/candidateService";
import PageLayout from "../layout/base";
import "../../../assets/css/login.css";

class PasswordRecover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      userName: "admin",
      userPassword: "",
      visiblePage: "LOGIN",
    };
  }

  componentDidMount() {
    document.title = "Récuperer le mot de passe";
  }

  onFinish = (values) => {
    console.log("values:", values);
    const { recover_email } = values;

    let bodyParams = new URLSearchParams();
    bodyParams.append("recover_email", recover_email);

    _passwordRecover(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        const { recoverRequest } = response;

        if (recoverRequest) {
          const { id, code, email } = recoverRequest;
          this.props.history.push(
            `/password/verify?email=${email}&token=${id}&code=${code}`
          );
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Impossible de vérifier votre email");
      });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <PageLayout>
        <main id={"content"} role={"main"} className={"site-wrapper-reveal"}>
          <section
            className={"bg-cover min-vh-100"}
            style={{
              backgroundColor: "#E7EBEF",
            }}
          >
            <div className="d-flex justify-content-center">
              <div className="page-content">
                <div className="login-card">
                  <div className="py-10">
                    <h2 className={"font-weight-bold display-2"}>
                      Récupérez votre mot de passe
                    </h2>
                    <Divider />
                    <Spin spinning={isLoading}>
                      <Form layout={"vertical"} onFinish={this.onFinish}>
                        <Row gutter={12}>
                          <Col span={24}>
                            <Form.Item
                              label={"Votre adresee email"}
                              name={"recover_email"}
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez compléter ce champs!",
                                },
                              ]}
                            >
                              <Input
                                size={"large"}
                                placeholder={"ex : email@example.com"}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />

                        <div className="d-flex justify-content-between">
                          <button
                            type={"submit"}
                            className="btn btn-block btn-success font-weight-bold"
                          >
                            Continuer
                          </button>
                        </div>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
              {/* / .row */}
            </div>
            {/* / .container */}
          </section>
        </main>
      </PageLayout>
    );
  }
}

export default PasswordRecover;
