import React, { Component } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import queryString from "query-string";
import {
  checkRegistrationInvite,
  confirmRegistrationInvite,
} from "../../api/candidateService";
import { setCookie, getCookie } from "../../helpers/cookies";
import { COUNTIRES_DATA } from "../../data/countriesList";
import moment from "moment";

class AccountInvite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkingInvitation: true,
      invitationID: null,
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    document.title = "Confirmez votre compte";

    // check url

    let parsed = queryString.parse(window.location.search);
    if (parsed.token) {
      this.setState({ invitationID: parsed.token }, () => {
        this.checkInvitation();
      });
    }
  }

  checkInvitation() {
    const { invitationID } = this.state;
    let params = { token: invitationID };
    checkRegistrationInvite(params)
      .then((result) => {
        this.setState({ checkingInvitation: false });

        let response = result.data;
        if (response.invitation) {
          this.fillForm(response.invitation);
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ checkingInvitation: false });
        message.error("Echec : Une erreur s'est produite / Lien invalide");

        this.props.history.push("/link-expired");
      });
  }

  fillForm(data) {
    const {
      email_address,
      family_name,
      first_name,
      last_name,
      phone1,
      phone2,
      address1,
      city,
      birth_date,
      birth_place,
      nationality,
    } = data;

    this.formRef.current.setFieldsValue({
      candidate_email: email_address,
      candidate_name: family_name,
      candidate_lastname: last_name,
      candidate_firstname: first_name,
      candidate_country: "CD",
      candidate_city: city,
      candidate_address1: address1,
      candidate_birthplace: birth_place,
      candidate_birthdate: birth_date ? moment(birth_date) : null,
    });
  }

  onFinish = (values) => {
    const { invitationID } = this.state;
    const {
      candidate_email,
      candidate_name,
      candidate_lastname,
      candidate_firstname,
      candidate_gender,
      candidate_country,
      candidate_city,
      candidate_address1,
      candidate_birthplace,
      candidate_birthdate,
      candidate_password,
      candidate_password_confirm,
    } = values;

    if (candidate_password_confirm != candidate_password) {
      message.error("Les mots de passe entrés ne correspondent pas!");
      return;
    }

    let birthDate = candidate_birthdate.format("YYYY-MM-DD");

    let bodyParams = new URLSearchParams();
    bodyParams.append("token", invitationID);
    bodyParams.append("candidate_email", candidate_email);
    bodyParams.append("candidate_name", candidate_name);
    bodyParams.append("candidate_lastname", candidate_lastname);
    bodyParams.append("candidate_firstname", candidate_firstname);
    bodyParams.append("candidate_gender", candidate_gender);
    bodyParams.append("candidate_country", candidate_country);
    bodyParams.append("candidate_city", candidate_city);
    bodyParams.append("candidate_address1", candidate_address1);
    bodyParams.append("candidate_birthplace", candidate_birthplace);
    bodyParams.append("candidate_birthdate", birthDate);
    bodyParams.append("candidate_password", candidate_password);
    bodyParams.append("candidate_password_confirm", candidate_password_confirm);

    this.setState({ checkInvitation: true });
    confirmRegistrationInvite(bodyParams)
      .then((result) => {
        this.setState({ checkInvitation: false });

        let response = result.data;
        if (response.access_token) {
          const { data } = response;

          sessionStorage.setItem("accessToken", response.access_token);
          sessionStorage.setItem("userFields", JSON.stringify(data));

          // trigger onAccountCreated
          message.success("Création du compte réussie !!");
          this.props.history.push("/account/profile");
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        this.setState({ checkInvitation: false });
        message.error("une erreur s'est produite. veuillez réessayer");
      });
  };

  render() {
    const { checkingInvitation } = this.state;
    return (
      <div>
        <section
          className={"bg-cover min-vh-100"}
          style={{
            backgroundColor: "#E7EBEF",
          }}
        >
          <div className="d-flex justify-content-center">
            <div className="page-content">
              <div className="login-card">
                <div className="py-10">
                  <Spin spinning={checkingInvitation}>{this.renderForm()}</Spin>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  renderForm() {
    return (
      <>
        <h2 className={"font-weight-bold display-2"}>
          Confirmez votre compte ITM Africa
        </h2>
        <Divider />
        <Form ref={this.formRef} layout={"vertical"} onFinish={this.onFinish}>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={"Votre nom"}
                name={"candidate_name"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input size={"large"} placeholder={"ex : Tshibanda"} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={"Votre Prénom"}
                name={"candidate_firstname"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input size={"large"} placeholder={"ex : Sabrina"} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={"Votre Postnom"}
                name={"candidate_lastname"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input size={"large"} placeholder={"ex : Mayobo"} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={"Vous êtes ?"}
                name={"candidate_gender"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Select size={"large"} placeholder={"Choisissez un sexe"}>
                  <Select.Option value={"FEMALE"}>Femme</Select.Option>
                  <Select.Option value={"MALE"}>Homme</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={"Lieu de naissance"}
                name={"candidate_birthdate"}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format={"DD-MM-YYYY"}
                  size={"large"}
                  placeholder={"Sélectionner date"}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={"Lieu de naissance"}
                name={"candidate_birthplace"}
              >
                <Input size={"large"} placeholder={"ex: Kananga"} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={"Votre pays de résidence"}
                name={"candidate_country"}
              >
                <Select size={"large"} placeholder={"Choisissez un pays"}>
                  {COUNTIRES_DATA.map((item, index) => (
                    <Select.Option key={index} value={item.code}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={"Votre ville"} name={"candidate_city"}>
                <Input size={"large"} placeholder={"Ex : Kinshasa"} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={"Votre adresse"} name={"candidate_address1"}>
            <Input size={"large"} />
          </Form.Item>
          <Divider />

          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                label={"Votre adresee email"}
                name={"candidate_email"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input size={"large"} placeholder={"ex : email@example.com"} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={"Entrez votre mot de passe"}
                name={"candidate_password"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input.Password size={"large"} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={"Confirmez votre mot de passe"}
                name={"candidate_password_confirm"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input.Password size={"large"} />
              </Form.Item>
            </Col>
          </Row>
          <Divider />

          <div className="">
            <button type={"submit"} className="btn btn-block btn-success">
              Confirmer mon compte
            </button>
          </div>
        </Form>
      </>
    );
  }
}

export default AccountInvite;
