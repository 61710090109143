import React, { Component } from "react";
import {
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import {
  createAccount,
  activateAccount,
  activateResend,
} from "../../api/candidateService";
import { COUNTIRES_DATA } from "../../data/countriesList";

class RegisterAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      requestCode: "",
      requestId: "",
      requestEmail: "",
      showActivation: false,
    };
  }

  componentDidMount() {}

  onFinish = (values) => {
    console.log("values:", values);

    const {
      candidate_country,
      candidate_email,
      candidate_firstname,
      candidate_gender,
      candidate_name,
      candidate_password,
      candidate_password_confirm,
    } = values;

    if (candidate_password != candidate_password_confirm) {
      message.error("Les mots de passe ne correspondent pas !");
      return;
    }

    let bodyParams = new URLSearchParams();
    bodyParams.append("candidate_firstname", candidate_firstname);
    bodyParams.append("candidate_name", candidate_name);
    bodyParams.append("candidate_gender", candidate_gender);
    bodyParams.append("candidate_email", candidate_email);
    bodyParams.append("candidate_country", candidate_country);
    bodyParams.append("candidate_password", candidate_password);

    this.setState({ isLoading: true });
    createAccount(bodyParams, { t: new Date().getTime() })
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.request) {
          const { request } = response;
          this.setState({
            requestId: request.id,
            requestEmail: request.email_address,
            showActivation: true,
          });
        } else if (response.error_type) {
          let errors = {};
          errors["account_taken"] =
            "Un compte extstant est associé à cette adresse email";
          errors["error_exception"] =
            "une erreur s'est produite. veuillez réessayer";
          message.error(errors[response.error_type]);
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });

        message.error("une erreur s'est produite. veuillez réessayer");
      });
  };

  onActivationFinish = (values) => {
    const { requestId, requestEmail } = this.state;
    const { onAccountCreated } = this.props;
    const { activation_code } = values;

    let bodyParams = new URLSearchParams();
    bodyParams.append("activation_id", requestId);
    bodyParams.append("activation_email", requestEmail);
    bodyParams.append("activation_code", activation_code);

    this.setState({ isLoading: true });
    activateAccount(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.access_token) {
          const { data } = response;

          sessionStorage.setItem("accessToken", response.access_token);
          sessionStorage.setItem("userFields", JSON.stringify(data));

          // trigger onAccountCreated
          let payloads = {};
          payloads["display_name"] = data.display_name;
          payloads["user_fields"] = data;
          if (onAccountCreated) onAccountCreated(payloads);
        } else if (response.error_type) {
          let errors = {};
          errors["code_invalid"] = "Le code entré est invalide";
          errors["resource_invalid"] = "Impossible de traiter votre demande";
          message.error(errors[response.error_type]);
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });

        message.error("une erreur s'est produite. veuillez réessayer");
      });
  };

  onResendCode() {
    const { requestId } = this.state;

    let bodyParams = new URLSearchParams();
    bodyParams.append("activation_id", requestId);

    this.setState({ isLoading: true });
    activateResend(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  onRequestLogin() {
    const { onRequestLogin } = this.props;
    if (onRequestLogin) onRequestLogin();
  }

  render() {
    const { isLoading, showActivation } = this.state;
    return (
      <div className="py-10">
        {!showActivation && (
          <>
            <h2 className={"font-weight-bold display-2"}>
              Créer un compte gratuit et postulez aux offres d'emploi
            </h2>
            <Divider />
            <Spin spinning={isLoading}>
              <Form layout={"vertical"} onFinish={this.onFinish}>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      label={"Votre nom"}
                      name={"candidate_name"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} placeholder={"ex : Tshibanda"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={"Votre Prénom"}
                      name={"candidate_firstname"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} placeholder={"ex : Sabrina"} />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label={"Vous êtes ?"}
                  name={"candidate_gender"}
                  rules={[
                    {
                      required: true,
                      message: "Veuillez compléter ce champs!",
                    },
                  ]}
                >
                  <Select size={"large"} placeholder={"Choisissez un sexe"}>
                    <Select.Option value={"FEMALE"}>Femme</Select.Option>
                    <Select.Option value={"MALE"}>Homme</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label={"Votre pays de résidence"}
                  name={"candidate_country"}
                >
                  <Select size={"large"} placeholder={"Choisissez un pays"}>
                    {COUNTIRES_DATA.map((item, index) => (
                      <Select.Option key={index} value={item.code}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Divider />

                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      label={"Votre adresee email"}
                      name={"candidate_email"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input
                        size={"large"}
                        placeholder={"ex : email@example.com"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={"Entrez votre mot de passe"}
                      name={"candidate_password"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input.Password size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={"Confirmez votre mot de passe"}
                      name={"candidate_password_confirm"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input.Password size={"large"} />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />

                <div className="d-flex justify-content-between">
                  <button type={"submit"} className="btn btn-success">
                    Créer mon compte
                  </button>
                  <button
                    type={"button"}
                    className="btn btn-outline-success"
                    onClick={() => this.onRequestLogin()}
                  >
                    J'ai déjà un compte
                  </button>
                </div>
              </Form>
            </Spin>
          </>
        )}

        {showActivation && (
          <>
            <h2 className={"font-weight-bold display-2"}>
              Un code d’activation a été envoyé dans votre adresse e-mail, si
              vous ne le trouvez pas dans la boite principale, s’il vous plait
              vérifiez vos spams
            </h2>
            <Divider />
            <Spin spinning={isLoading}>
              <Form layout={"vertical"} onFinish={this.onActivationFinish}>
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      label={"Entrez le code d'activation"}
                      name={"activation_code"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} placeholder={"ex : 23091"} />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />
                <Space>
                  <button type={"submit"} className="btn btn-success">
                    Activer mon compte
                  </button>
                  <button
                    type={"button"}
                    className="btn btn-outline-dark"
                    onClick={() => this.onResendCode()}
                  >
                    Renvoyer le code
                  </button>
                </Space>
              </Form>
            </Spin>
          </>
        )}
      </div>
    );
  }
}

export default RegisterAccount;
