import { Divider } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageLayout from "../layout/base";
import "../../../assets/css/profile.css";

class ProfileIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: "Utilisateur",
    };
  }

  componentDidMount() {
    document.title = "Profile Utilisateur";
    this.getProfile();
  }

  getProfile() {
    const data = sessionStorage.getItem("userFields");
    if (data) {
      let userFields = JSON.parse(data);
      if (userFields.id) this.setState({ fullName: userFields.display_name });
    }
  }

  render() {
    const { fullName } = this.state;
    return (
      <PageLayout>
        <section className="accordion">
          <div className="container">
            <div className="container-detail-box">
              <h1 className="font-weight-bold">Bonjour {fullName}</h1>
              <Divider />

              <Divider />
              <ul style={{ paddingInlineStart: 0 }}>
                <li>
                  <Link to={"/account/resume"} className="profile-link">
                    <span className="profile-link-text">Compléter mon dossier</span>
                    <span className="fe fe-file-text"></span>
                  </Link>
                </li>
                <li>
                  <Link to={"/account/resume/upload"} className="profile-link">
                    <span className="profile-link-text">Uploader le CV</span>
                    <span className="fe fe-upload"></span>
                  </Link>
                </li>
                <li>
                  <Link to={"/account/password"} className="profile-link">
                    <span className="profile-link-text">
                      Modifier votre mot de passe
                    </span>
                    <span className="fe fe-unlock"></span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => this.handleLogout()}
                    className="profile-link"
                  >
                    <span className="profile-link-text">
                      Se Deconnecter du compte
                    </span>
                    <span className="fe fe-log-out"></span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </PageLayout>
    );
  }

  handleLogout() {
    sessionStorage.clear();
    this.props.history.push("/account/signin");
  }
}

export default ProfileIndex;
