import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Error404 from "../components/errors/404";
import HomeIndex from "../components/home";
import JobView from "../components/job";
import ApplyIndex from "../components/apply";
import LoginIndex from "../components/login";
import ResumeIndex from "../components/resume";
import ProfileIndex from "../components/profile";
import AccountPassword from "../components/profile/password";
import ResumeUpload from "../components/profile/upload";
import ApplyResume from "../components/apply/resume";
import ApplyResumeFile from "../components/apply/resumeFile";
import AccountInvite from "../components/invite";
import PasswordRecover from "../components/password/email";
import PasswordReCode from "../components/password/verify";
import PasswordReset from "../components/password/reset";

class RoutesIndex extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={HomeIndex} />
          <Route exact path="/view" component={JobView} />
          <Route exact path="/apply" component={ApplyResume} />
          <Route exact path="/apply/resume" component={ApplyResume} />
          <Route exact path="/apply/resume-file" component={ApplyResumeFile} />
          <Route exact path="/account/invite" component={AccountInvite} />
          <Route exact path="/account/signin" component={LoginIndex} />
          <Route exact path="/account/profile" component={ProfileIndex} />
          <Route exact path="/account/password" component={AccountPassword} />
          <Route exact path="/account/resume" component={ResumeIndex} />
          <Route exact path="/account/resume/upload" component={ResumeUpload} />

          <Route exact path="/password/recover" component={PasswordRecover} />
          <Route exact path="/password/verify" component={PasswordReCode} />
          <Route exact path="/password/reset" component={PasswordReset} />

          <Route component={Error404} />
        </Switch>
      </div>
    );
  }
}
export default RoutesIndex;
