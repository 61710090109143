import React, { Component } from "react";
import { Col, Divider, Form, Input, Spin, Row, message } from "antd";
import queryString from "query-string";
import { passwordRecoverVerify } from "../../api/candidateService";
import PageLayout from "../layout/base";
import "../../../assets/css/login.css";

class PasswordReCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      userName: "admin",
      userPassword: "",
      visiblePage: "LOGIN",
    };
  }

  componentDidMount() {
    document.title = "Récuperer le mot de passe";
    let parsed = queryString.parse(window.location.search);
    const { email, code, token } = parsed;
    if (email) this.setState({ verification_email: email });
    if (token) this.setState({ verification_token: token });
    if (code) this.setState({ verification_code: code });
  }

  onFinish = (values) => {
    const { verification_token } = this.state;

    console.log("values:", values);
    const { verification_code } = values;

    let bodyParams = new URLSearchParams();
    bodyParams.append("token", verification_token);
    bodyParams.append("code", verification_code);

    this.setState({ isLoading: true });
    passwordRecoverVerify(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        const { verificationRequest, error_type } = response;

        if (verificationRequest) {
          const { token } = verificationRequest;
          window.sessionStorage.setItem("verificationRequestToken", token);

          this.props.history.push(`/password/reset?token=${token}`);
        } else if (error_type) {
          if (error_type === "code_invalid") {
            message.error("Le code entré est invalide");
          } else {
            message.error("Impossible de vérifier votre code");
          }
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Impossible de vérifier votre code");
      });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <PageLayout>
        <main id={"content"} role={"main"} className={"site-wrapper-reveal"}>
          <section
            className={"bg-cover min-vh-100"}
            style={{
              backgroundColor: "#E7EBEF",
            }}
          >
            <div className="d-flex justify-content-center">
              <div className="page-content">
                <div className="login-card">
                  <div className="py-10">
                    <h2 className={"font-weight-bold display-2"}>
                      Récupérez votre mot de passe
                    </h2>
                    <Divider />
                    <Spin spinning={isLoading}>
                      <Form layout={"vertical"} onFinish={this.onFinish}>
                        <Row gutter={12}>
                          <Col span={24}>
                            <Form.Item
                              label={"Code de confirmation"}
                              name={"verification_code"}
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez compléter ce champs!",
                                },
                              ]}
                            >
                              <Input
                                size={"large"}
                                placeholder={"ex : 123456"}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />

                        <div className="d-flex justify-content-between">
                          <button
                            type={"submit"}
                            className="btn btn-block btn-success font-weight-bold"
                          >
                            Vérifier le code
                          </button>
                        </div>
                        <div className="my-3">
                          <button
                            type={"submit"}
                            className="btn btn-block btn-outline-primary font-weight-bold"
                          >
                            Vous n'avez pas réçu le code ?
                          </button>
                        </div>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
              {/* / .row */}
            </div>
            {/* / .container */}
          </section>
        </main>
      </PageLayout>
    );
  }
}

export default PasswordReCode;
