import React, { Component } from "react";
import { Col, Divider, Form, Input, message, Row, Space, Spin } from "antd";
import { authAccount } from "../../api/candidateService";
import { saveToEntityCache } from "../../helpers/cache";

class LoginAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {}

  onFinish = (values) => {
    console.log("values:", values);
    const { onAccountLogged } = this.props;
    const { candidate_email, candidate_password } = values;

    let bodyParams = new URLSearchParams();
    bodyParams.append("candidate_email", candidate_email);
    bodyParams.append("candidate_password", candidate_password);

    this.setState({ isLoading: true });
    authAccount(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.access_token) {
          const { data } = response;

          let userEntites = data.entities;
          Array.from(userEntites).forEach((item) => {
            saveToEntityCache(item);
          });

          sessionStorage.setItem("accessToken", response.access_token);
          sessionStorage.setItem("userFields", JSON.stringify(data));

          // trigger onAccountLogged
          let payloads = {};
          payloads["display_name"] = data.display_name;
          payloads["user_fields"] = data;
          if (onAccountLogged) onAccountLogged(payloads);
        } else if (response.error_type) {
          let errors = {};
          errors["account_invalid"] =
            "Aucun compte associé à cette adresse email";
          errors["password_invalid"] = "Le mot de passe est invalide";
          message.error(errors[response.error_type]);
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("une erreur s'est produite. veuillez réessayer");
      });
  };

  onRequestRegister() {
    const { onRequestRegister } = this.props;
    if (onRequestRegister) onRequestRegister();
  }

  onRequestPassword() {
    const { onRequestPassword } = this.props;
    if (onRequestPassword) onRequestPassword();
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className="py-10">
        <h2 className={"font-weight-bold display-2"}>
          Connectez-vous à votre compte et récupérez votre CV
        </h2>
        <Divider />
        <Spin spinning={isLoading}>
          <Form layout={"vertical"} onFinish={this.onFinish}>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  label={"Votre adresee email"}
                  name={"candidate_email"}
                  rules={[
                    {
                      required: true,
                      message: "Veuillez compléter ce champs!",
                    },
                  ]}
                >
                  <Input
                    size={"large"}
                    placeholder={"ex : email@example.com"}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={"Entrez votre mot de passe"}
                  name={"candidate_password"}
                  rules={[
                    {
                      required: true,
                      message: "Veuillez compléter ce champs!",
                    },
                  ]}
                >
                  <Input.Password size={"large"} />
                </Form.Item>
              </Col>
            </Row>
            <Divider />

            <div className="d-flex justify-content-between">
              <button
                type={"submit"}
                className="btn btn-success font-weight-bold"
              >
                Se Connecter
              </button>
              <button
                type={"button"}
                className="btn btn-outline-success font-weight-bold"
                onClick={() => this.onRequestPassword()}
              >
                Mot de passe oublié
              </button>
            </div>

            <div className="d-flex justify-content-between justify-content-end align-items-end mt-5">
              <button
                type={"button"}
                className="btn btn-block btn-outline-primary font-weight-bold"
                onClick={() => this.onRequestRegister()}
              >
                Créer un compte
              </button>
            </div>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default LoginAccount;
