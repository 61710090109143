import React, { Component } from "react";
import moment from "moment";
import {
  Col,
  Divider,
  Form,
  Row,
  Select,
  Spin,
  message,
  Modal,
  Space,
} from "antd";
import queryString from "query-string";
import {
  singleJob,
  getUsersDomains,
  getCompanySectors,
  sendApplication,
} from "../../api/jobService";
import {
  getEntityCache,
  deleteEntityCache,
  updateUserFields,
} from "../../helpers/cache";
import { deleteEntity } from "../../api/candidateService";
import { userConnected } from "../../utils/token";
import PageLayout from "../layout/base";
import ResumeFile from "./file";

class ApplyResumeFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobId: null,
      jobContent: null,
      jobData: null,
      resumeFile: null,
      isLoading: true,
      loadingDomains: true,
      domainsItems: [],
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    let parsed = queryString.parse(window.location.search);
    setTimeout(() => this.autoFill(), 2000);
    if (parsed.id) {
      this.setState({ jobId: parsed.id }, () => {
        this.apiFetchData();
      });
    }
  }

  autoFill() {
    if (!userConnected()) return;

    let json = sessionStorage.getItem("userFields");
    if (!json) return;
    let userFields = JSON.parse(json);

    if (userFields.primary_domain_id != "") {
      let candidate_domains = `${userFields.primary_domain_id}`.split(",");
      this.formRef.current.setFieldsValue({ candidate_domains });
    }
  }

  apiFetchData() {
    const { jobId } = this.state;
    singleJob(jobId, { t: Date.now() })
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.id) {
          this.setState({ jobContent: response, jobData: response.data });
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });

    getUsersDomains().then((result) => {
      this.setState({ loadingDomains: false });

      let response = result.data;
      if (response.data) {
        this.setState({ domainsItems: response.data });
      } else {
        throw new Error("error");
      }
    });

    getCompanySectors().then((result) => {
      this.setState({ loadingDomains: false });

      let response = result.data;
      if (response.data) {
        this.setState({ companySectorsItems: response.data });
      } else {
        throw new Error("error");
      }
    });
  }

  onFinish = (values) => {
    console.log("onFinish: ", values);

    const { educationsList, resumeFile, jobId } = this.state;
    const { candidate_domains, estimated_salary } = values;

    let profileData = sessionStorage.getItem("userFields");
    let userFields = JSON.parse(profileData);

    if (resumeFile == null) {
      message.error("Veuillez joindre un CV");
      return;
    }

    let bodyParams = new URLSearchParams();
    bodyParams.append("job_reference", jobId);
    bodyParams.append("candidate_resume", resumeFile.file);
    bodyParams.append("candidate_reference", userFields.id);
    bodyParams.append("estimated_salary", estimated_salary);
    bodyParams.append(
      "candidate_domains",
      Array.from(candidate_domains).join(",")
    );

    this.setState({ isLoading: true });
    sendApplication(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.application_id) {
          Modal.success({
            title: "Reussi!",
            okText: "Fermer",
            content:
              "Votre candidature a été envoyée, vous recevrez dans un bref délai les avancées sur la présélection de votre dossier",
            onOk: () => {
              this.props.history.push("/");
            },
            onCancel: () => {
              this.props.history.push("/");
            },
          });
        } else if (response.error_type) {
          let errors = {};
          errors["already_applied"] = "Vous avez déjà postulé à cette offre";
          errors["error_exception"] =
            "Erreur survenue lors de l'envoie de votre candidature";
          message.error(errors[response.error_type]);
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        Modal.error({
          title: "Echec",
          content: "Erreur survenue lors de l'envoie de votre candidature",
          okText: "Fermer",
          onOk: () => {},
        });
      });
  };

  /**
   * onGotFile
   * handle after retrieved resume file
   */

  onGotFile(resumeFile) {
    console.log({ resumeFile });
    this.setState({ resumeFile });
  }

  /**
   * onClearFile
   * handle after user clear resume file
   */

  onClearFile() {
    console.log({ resumeFile: null });
  }

  render() {
    const { jobContent, jobData, isLoading } = this.state;
    return (
      <PageLayout>
        <Spin spinning={isLoading}>
          <section className="inner-header-page">
            <div className="container">
              <div className="col-md-8">
                <div className="left-side-container">
                  <div className="freelance-image">
                    <a href="#">
                      <img
                        src={require("../../../assets/img/logos/default.jpg")}
                        className="img-responsive"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="header-details">
                    <h4>Postuler à l'offre</h4>
                    <p>Formulaire de candidature</p>
                    <ul>
                      <li>
                        <a href="single-company-profile.html">
                          <i className="fa fa-user" />{" "}
                          {jobContent && jobContent.available_position} Poste(s)
                        </a>
                      </li>
                      <li>
                        <div className="verified-action">Vérifié</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 bl-1 br-gary">
                <div className="right-side-detail">
                  {jobContent && (
                    <ul>
                      <li>
                        <span className="detail-info">Durée</span>
                        {jobContent.contrat_duration}
                      </li>
                      <li>
                        <span className="detail-info">Experience</span>
                        {`${jobData.experience_years} ans`}
                      </li>
                      <li>
                        <span className="detail-info">Emplacement</span>
                        {jobContent.locations}
                      </li>
                    </ul>
                  )}

                  <ul className="social-info">
                    <li>
                      <a
                        href="https://www.facebook.com/itmafrica/"
                        target={"_blank"}
                      >
                        <i className="fe fe-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/ItmRdc" target={"_blank"}>
                        <i className="fe fe-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/itm-africa-7526a6162/detail/recent-activity/"
                        target={"_blank"}
                      >
                        <i className="fe fe-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UC9b7Hg1wrog88Qv7PIeDeng"
                        target={"_blank"}
                      >
                        <i className="fe fe-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {jobData && (
            <section>
              <div className="container">{this.pageContainer()}</div>
            </section>
          )}
        </Spin>
      </PageLayout>
    );
  }

  pageContainer() {
    const { loadingDomains, domainsItems, jobContent } = this.state;
    const isConnected = userConnected();
    return (
      <div className="col-md-10 col-sm-12">
        {!userConnected() && (
          <div className="container-detail-box">
            <h3 className="font-weight-bold">Connectez-vous à votre compte</h3>
            <h5>Afin de récupérer votre CV</h5>
            <Divider />
            <Space>
              <button
                type={"button"}
                className="btn btn-primary"
                onClick={() => this.setState({ modalLoginOpen: true })}
              >
                Se Connecter
              </button>
              <button
                type={"button"}
                className="btn btn-dark"
                onClick={() => this.setState({ modalRegisterOpen: true })}
              >
                Créer un compte
              </button>
            </Space>
          </div>
        )}

        {isConnected && (
          <div className="container-detail-box">
            <div className="apply-job-header">
              <h4>{jobContent.title}</h4>
              <a href="#!" className="cl-success">
                <span>
                  <i className="fe fe-globe" />
                  ITM
                </span>
              </a>
              <span>
                <i className="fe fe-map-pin" />
                {jobContent.locations}
              </span>
            </div>

            <div>
              <Form
                ref={this.formRef}
                layout="vertical"
                onFinish={this.onFinish}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name={"candidate_domains"}
                      label={"Domaine(s) de Métier"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        loading={loadingDomains}
                        size={"large"}
                        mode={"multiple"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                        showArrow={true}
                        showSearch={true}
                        onSearch={(val) => console.log("onSearch:", val)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {domainsItems.map((item, index) => (
                          <Select.Option key={index} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={"estimated_salary"}
                      label={"Estimation salariale (en Dollars US)"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        placeholder={"Séléctionner"}
                        style={{ width: "100%" }}
                      >
                        <Select.Option value={"0_300"}>
                          Moins de 300$
                        </Select.Option>
                        <Select.Option value={"300_500"}>
                          entre 300$ et 500$
                        </Select.Option>
                        <Select.Option value={"500_750"}>
                          entre 500$ et 750$
                        </Select.Option>
                        <Select.Option value={"750_1000"}>
                          entre 750$ et 1000$
                        </Select.Option>
                        <Select.Option value={"1000_1500"}>
                          entre 1000$ et 1500$
                        </Select.Option>
                        <Select.Option value={"1500_2000"}>
                          Plus de 1500$
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <h4>Votre CV (document en attache)</h4>
                <ResumeFile
                  onGotFile={(file) => this.onGotFile(file)}
                  onClearFile={() => this.onClearFile()}
                />
                <Divider />
                <button
                  type={"button"}
                  className="btn btn-success"
                  onClick={() => {
                    this.formRef.current.submit({});
                  }}
                >
                  Postuler à cette offre
                </button>
              </Form>
            </div>
          </div>
        )}
        <div className="job-detail-statistic flex-middle-sm">
          <div className="statistic-item flex-middle">
            <div className="icon text-theme">
              <i className="fe fe-phone-call theme-cl" />
            </div>
            <span className="text">(+243) 82 85 02 119</span>
          </div>
          <div className="statistic-item flex-middle">
            <div className="icon text-theme">
              <i className="fe fe-mail theme-cl" />
            </div>
            <span className="text">contact@itmafrica.com</span>
          </div>
          <div className="statistic-item flex-middle">
            <div className="icon text-theme">
              <i className="fe fe-linkedin theme-cl" />
            </div>
            <span className="text">itmafrica</span>
          </div>
        </div>
        {/* Similar Jobs */}
      </div>
    );
  }
}

export default ApplyResumeFile;
