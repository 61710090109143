import axios from "axios";
import { API_BASE_DEV, API_BASE_PROD } from "../constants/api";
import { ORGANIZATION_ID } from "../constants/app";
import { getCookie } from "../helpers/cookies";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
    }

    return Promise.reject(error.response);
  }
);

const ApiContext = axios.create({
  baseURL: API_BASE_PROD,
  withCredentials: false,
  timeout: 30000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Organization-ID": ORGANIZATION_ID,
  },
});

/**
 * JOBS
 */

export function getJobs(params = {}) {
  params["access_token"] = getCookie("accessToken");
  return ApiContext.get(`public-api/list`, {
    params,
  });
}

export function singleJob(id, params = {}) {
  params["access_token"] = getCookie("accessToken");
  return ApiContext.get(`public-api/detail/${id}`, {
    params,
  });
}

/**
 * PUBLIC DATA
 */

export function getUsersDomains(params = {}) {
  params["access_token"] = getCookie("accessToken");
  return ApiContext.get(`p/user-domains`, {
    params,
  });
}

export function getEducationDomains(params = {}) {
  params["access_token"] = getCookie("accessToken");
  return ApiContext.get(`p/study-domains`, {
    params,
  });
}

export function getEducationLevels(params = {}) {
  params["access_token"] = getCookie("accessToken");
  return ApiContext.get(`p/study-levels`, {
    params,
  });
}

export function getCompanySectors(params = {}) {
  params["access_token"] = getCookie("accessToken");
  return ApiContext.get(`p/companies-sectors`, {
    params,
  });
}

export function sendApplication(bodyParams, params = {}) {
  params["access_token"] = getCookie("accessToken");
  return ApiContext.post(`public-api/apply`, bodyParams, { params });
}
