import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Divider, message, Spin } from "antd";
import moment from "moment";
import LayoutArtist from "../layout/base";
import { getJobs } from "../../api/jobService";

class HomeIndex extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      contratTypes: [
        {
          id: "4656565454",
          name: "CDD",
        },
        {
          id: "5756565454",
          name: "CDI",
        },
        {
          id: "4646565454",
          name: "Practice",
        },
        {
          id: "4756565454",
          name: "Project-based",
        },
        {
          id: "5646565454",
          name: "Temporaire",
        },
      ],
      itemsList: [],
      isLoading: true,
      isSearching: false,
      searchTerm: "",
      searchLocation: "",
      searchContratType: "",
    };
  }

  componentDidMount() {
    document.title = "ITM - Offres d'emploi";
    setTimeout(() => this.apiRequest(), 500);
  }

  apiRequest() {
    getJobs({})
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.items) {
          const itemsList = response.items;
          console.log("itemsList", itemsList);
          this.setState({ itemsList });
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Impossible de charger les offres");
      });
  }

  onSearch() {
    const { searchContratType, searchLocation, searchTerm } = this.state;

    let queryParams = {};
    if (searchTerm != "") queryParams["q_term"] = searchTerm;
    if (searchLocation != "") queryParams["q_location"] = searchLocation;
    if (searchContratType != null) queryParams["q_contrat"] = searchContratType;

    this.setState({ isLoading: true, isSearching: true });
    getJobs(queryParams)
      .then((result) => {
        this.setState({ isLoading: false, isSearching: false });

        let response = result.data;
        if (response.items) {
          const itemsList = response.items;
          console.log("itemsList", itemsList);
          this.setState({ itemsList });
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false, isSearching: false });
        message.error("Impossible de charger les offres");
      });
  }

  render() {
    const { itemsList, contratTypes, isLoading, isSearching } = this.state;
    return (
      <LayoutArtist>
        {/* Title Header Start */}
        <section
          className="inner-header-title"
          style={{ backgroundImage: "url(assets/img/banner-10.jpg)" }}
        >
          <div className="container">
            <h1>Offres d'emplois</h1>
          </div>
        </section>
        <div className="clearfix" />
        {/* Title Header End */}

        <section>
          <div className="container">
            {/* Company Searrch Filter Start */}
            <Spin spinning={isSearching}>
              <div className="row extra-mrg mt-3">
                <div className="wrap-search-filter">
                  <form
                    onSubmit={(ev) => {
                      ev.preventDefault();
                      this.onSearch();
                    }}
                  >
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mot clé: Jardinier, Product Designer, Comptable"
                          onChange={(ev) => {
                            let searchTerm = ev.target.value;
                            this.setState({ searchTerm });
                          }}
                        />
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Emplacement: Ville, Province"
                          onChange={(ev) => {
                            let searchLocation = ev.target.value;
                            this.setState({ searchLocation });
                          }}
                        />
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <select
                          className="form-control custom-select custom-select-lg"
                          id="j-category"
                          placeholder={"Type de contrat"}
                          onChange={(ev) => {
                            let searchContratType = ev.target.value;
                            this.setState({ searchContratType });
                          }}
                        >
                          <option disabled selected value>
                            Type de contrat
                          </option>
                          {contratTypes.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-2 col-sm-6">
                        <button
                          type="submit"
                          className="btn btn-primary full-width"
                        >
                          Rechercher
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Spin>
            {/* Company Searrch Filter End */}
            <div className="row" id={"jobs-container"}>
              <div className="col-12 col-md-4 col-lg-4 col-xl-12">
                {this.renderSidebar()}
              </div>
              <div className="col-12 col-md-8">
                <Spin spinning={isLoading}>
                  {itemsList.map((jobItem, index) => (
                    <div className="item-click" key={index}>
                      <article>
                        <div className="brows-job-list">
                          <div className="col-md-10 col-sm-10">
                            <div className="item-fl-box">
                              <div className="brows-job-company-img">
                                <a href="job-detail.html">
                                  <img
                                    src={require("../../../assets/img/logos/default.jpg")}
                                    className="img-responsive"
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div className="brows-job-position">
                                <h3>
                                  <Link to={`/view?id=${jobItem.id}`}>
                                    {jobItem.title}
                                  </Link>
                                </h3>
                                <p>
                                  <span className="brows-job-sallery">
                                    <i className="fe fe-map-pin" />
                                    {jobItem.locations}
                                  </span>

                                  <span className="brows-job-sallery">
                                    <i className="fe fe-calendar" />
                                    {moment(jobItem.publish_time)
                                      .locale("fr")
                                      .format("DD. MMM")}
                                  </span>

                                  <span className="brows-job-sallery">
                                    <i className="fe fe-user" />
                                    {jobItem.available_position} poste(s)
                                  </span>

                                  <span className="job-type bg-trans-success cl-success">
                                    {jobItem.contrat_duration}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 col-sm-2">
                            <div className="brows-job-link">
                              {!jobItem.expired && (
                                <Link
                                  to={`/view?id=${jobItem.id}`}
                                  className="btn btn-primary"
                                >
                                  Postuler
                                </Link>
                              )}
                              {jobItem.expired && (
                                <a
                                  href={`#/expired`}
                                  className="btn btn-outline-danger"
                                >
                                  Clôturée
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  ))}
                </Spin>
              </div>
            </div>

            {/*/.row*/}
          </div>
        </section>
      </LayoutArtist>
    );
  }

  renderSidebar() {
    return (
      <div>
        <div className="sidebar-widget newslatter-widget">
          <div className="widget-content">
            <h4 style={{ textTransform: "uppercase", fontSize: "19px" }}>
              Votre espace emploi
            </h4>
            <p>Créez votre compte et postulez directement en ligne </p>
            <Link
              to="/account/signin"
              className="theme-btn btn-style-one btn-rounded"
            >
              Connexion
            </Link>
          </div>
        </div>
        <div className="sidebar-widget support-widget">
          <div className="widget-content">
            <span
              className="icon flaticon-speaker-2"
              style={{ fontWeight: 700, color: "#FFF" }}
            />
            <div className="text font-weight-bold">
              Vous avez une question, <br /> une préoccupation ?
            </div>
            <div className="number" style={{ color: "#FFF" }}>
              Notre forum est pour vous !
            </div>
            <p className="mt-3">
              <a
                href="https://itmafrica.com/nous-contacter"
                title="Forum"
                target={"_blank"}
                className="theme-btn btn-style-three btn-rounded"
              >
                POSEZ VOTRE QUESTION
              </a>
            </p>
            <div className="email mt-3">
              <a href="forum" title="Forum">
                Toute une communauté est prête à vous répondre.
              </a>
            </div>
          </div>
        </div>
        <div className="sidebar-widget ad-widget">
          <div className="widget-content">
            <div className="image-box">
              <figure className="image">
                <img
                  src={require("../../../assets/img/covers/ad-img.jpg")}
                  alt=""
                />
              </figure>
              <div className="content-box">
                <h4 className="font-weight-bold">Nous recrutons</h4>
                <p>
                  <span className="font-weight-bold" style={{ color: "#FFF" }}>
                    Laissez-nous votre CV
                  </span>
                </p>
                <Link to="/account/signin" className="apply-link">
                  Envoyer votre CV
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar-widget support-widget">
          <div className="widget-content">
            <span
              className="icon flaticon-briefcase"
              style={{ color: "#FFF" }}
            />
            <div className="text font-weight-bold">
              Vous êtes un recruteur ? <br />{" "}
              <span style={{ fontSize: "13px" }}>
                Diffusez vos offres d'emploi auprès d'une large audience!{" "}
              </span>
            </div>
            <div className="number" style={{ color: "#FFF" }}>
              (+243) 828 502 119
            </div>
            <div className="email">
              <a href="mailto:info@itmafrica.com">info@itmafrica.com</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeIndex;
