import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HomeHeader from "../header/home";

class BaseLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { children } = this.props;
    return (
      <>
        <HomeHeader />
        <div className="wrapper">{children}</div>
      </>
    );
  }
}

export default BaseLayout;
