import React, { Component } from "react";
import { Button, Col, Divider, Form, Input, message, Row, Select } from "antd";
import countriesList from "../../data/countries";
import moment from "moment";
import { updateEntity } from "../../api/candidateService";
import { saveToEntityCache } from "../../helpers/cache";

class EducationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      yearsList: [],
      resourceID: null,
      endYearVisible: false,
      educationType: "academic",
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    let yearsList = [];
    var firstYear = moment().format("YYYY");

    for (var i = firstYear; i >= 1950; i--) {
      yearsList.push(i);
    }

    this.setState({ yearsList });
  }

  componentWillUnmount() {
    if (this.formRef) {
      this.formRef.current.resetFields();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { resourceId, entityParam } = props;
    if (entityParam) {
      if (resourceId != state.resourceID) {
        return {
          resourceID: resourceId,
        };
      }
    }

    console.log("props", props);
    console.log("state", state);
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { entityParam } = this.props;
    if (entityParam) {
      this.formRef.current.setFieldsValue({
        education_type: entityParam.education_type,
        date_debut_month: entityParam.date_debut_month,
        date_debut_year: entityParam.date_debut_year,
        date_end_month: entityParam.date_end_month,
        date_end_year: entityParam.date_end_year,
        education_city: entityParam.education_city,
        education_country: entityParam.education_country,
        education_domain: entityParam.education_domain,
        education_institute: entityParam.education_institute,
        education_level: entityParam.education_level,
        education_course_name: entityParam.education_course_name
          ? entityParam.education_course_name
          : "",
      });
    }
  }

  onFinish = (values) => {
    const { resourceID } = this.state;
    const {
      educationDomains,
      educationLevels,
      professionalDomains,
    } = this.props;
    const {
      date_debut_month,
      date_debut_year,
      education_city,
      education_country,
      education_domain,
      education_institute,
      education_level,
      education_type,
      date_end_month,
      date_end_year,
      education_course_name,
    } = values;

    let data = {
      education_type,
      education_country,
      education_domain,
      education_institute,
      date_debut_month,
      date_debut_year,
      education_city,
    };

    if (resourceID) data["id"] = resourceID;
    if (education_type == "academic") {
      let entity = educationDomains.find((e) => e.id == education_domain);
      data["education_domain_text"] = entity.name;
    } else {
      let entity = professionalDomains.find((e) => e.id == education_domain);
      data["education_domain_text"] = entity.name;
    }

    if (education_level) {
      let entity = educationLevels.find((e) => e.id == education_level);
      data["education_level"] = education_level;
      data["education_level_text"] = entity.name;
    }

    if (date_end_month && date_end_year) {
      data["date_end_month"] = date_end_month;
      data["date_end_year"] = date_end_year;
    }
    if (education_course_name)
      data["education_course_name"] = education_course_name;

    this.updateData(data);
  };

  updateData = (data) => {
    console.log("updateData:", data);
    const { onFormSubmit } = this.props;
    const { resourceID } = this.state;

    let bodyParams = new URLSearchParams();
    if (resourceID) bodyParams.append("entity_id", resourceID);
    bodyParams.append("entity_type", "education");
    bodyParams.append("entity_data", JSON.stringify(data));

    this.setState({ isLoading: true });
    updateEntity(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.created) {
          const { payloads } = response;
          saveToEntityCache(payloads);

          if (onFormSubmit) onFormSubmit(data);
          this.formRef.current.resetFields();
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        console.log("error:", err);
        this.setState({ isLoading: false });
        message.error("Impossible d'enregistrer vos informations");
      });
  };

  render() {
    const { yearsList, educationType, isLoading } = this.state;
    const {
      educationDomains,
      educationLevels,
      professionalDomains,
    } = this.props;
    return (
      <div>
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          layout="vertical"
          initialValues={{ education_type: educationType }}
        >
          <Row>
            <Col span={12}>
              <Form.Item name={"education_type"} label={"Type d'éducation"}>
                <Select
                  size={"large"}
                  placeholder={"Séléctionner dans la liste"}
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    this.setState({ educationType: value }, () => {
                      this.formRef.current.setFieldsValue({
                        education_domain: null,
                      });
                    })
                  }
                >
                  <Select.Option value="academic">Académique</Select.Option>
                  <Select.Option value="professional">
                    Professionnel
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            {educationType == "academic" && (
              <>
                <Col span={24}>
                  <Form.Item
                    name={"education_domain"}
                    label={"Domaine d'étude"}
                    rules={[
                      {
                        required: true,
                        message: "Veuillez compléter ce champs!",
                      },
                    ]}
                  >
                    <Select
                      size={"large"}
                      placeholder={"Séléctionner dans la liste"}
                      showSearch={true}
                      onSearch={(val) => console.log("onSearch:", val)}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: "100%" }}
                    >
                      {educationDomains.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name={"education_level"}
                    label={"Niveau d'études"}
                    rules={[
                      {
                        required: true,
                        message: "Veuillez compléter ce champs!",
                      },
                    ]}
                  >
                    <Select
                      size={"large"}
                      placeholder={"Séléctionner dans la liste"}
                      style={{ width: "100%" }}
                    >
                      {educationLevels.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name={"education_institute"}
                    label={"Inst. Universitaire / Supérieur / Scolaire"}
                    rules={[
                      {
                        required: true,
                        message: "Veuillez compléter ce champs!",
                      },
                    ]}
                  >
                    <Input size={"large"} />
                  </Form.Item>
                </Col>
              </>
            )}

            {educationType == "professional" && (
              <>
                <Col span={24}>
                  <Form.Item
                    name={"education_domain"}
                    label={"Domaine d'étude"}
                    rules={[
                      {
                        required: true,
                        message: "Veuillez compléter ce champs!",
                      },
                    ]}
                  >
                    <Select
                      size={"large"}
                      placeholder={"Séléctionner dans la liste"}
                      style={{ width: "100%" }}
                      showSearch={true}
                      onSearch={(val) => console.log("onSearch:", val)}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {professionalDomains.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name={"education_course_name"}
                    label={"Nom de la formation"}
                    rules={[
                      {
                        required: true,
                        message: "Veuillez compléter ce champs!",
                      },
                    ]}
                  >
                    <Input size={"large"} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name={"education_institute"}
                    label={"Nom de l'Insitution / Centre de formation"}
                    rules={[
                      {
                        required: true,
                        message: "Veuillez compléter ce champs!",
                      },
                    ]}
                  >
                    <Input size={"large"} />
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={12}>
              <Form.Item
                name={"education_country"}
                label={"Pays"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Select
                  size={"large"}
                  placeholder={"Séléctionner dans la liste"}
                  style={{ width: "100%" }}
                  showSearch={true}
                >
                  {countriesList.map((item, index) => (
                    <Select.Option value={item.label} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"education_city"}
                label={"Ville"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={"date_debut_month"}
                label={"Mois début"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Select
                  size={"large"}
                  placeholder={"Séléctionner dans la liste"}
                  style={{ width: "100%" }}
                >
                  <Select.Option value={"01"}>Janvier</Select.Option>
                  <Select.Option value={"02"}>Février</Select.Option>
                  <Select.Option value={"03"}>Mars</Select.Option>
                  <Select.Option value={"04"}>Avril</Select.Option>
                  <Select.Option value={"05"}>Mai</Select.Option>
                  <Select.Option value={"06"}>Juin</Select.Option>
                  <Select.Option value={"07"}>Juillet</Select.Option>
                  <Select.Option value={"08"}>Aôut</Select.Option>
                  <Select.Option value={"09"}>Septembre</Select.Option>
                  <Select.Option value={"10"}>Octobre</Select.Option>
                  <Select.Option value={"11"}>Novembre</Select.Option>
                  <Select.Option value={"12"}>Décembre</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"date_debut_year"}
                label={"Année début"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez compléter ce champs!",
                  },
                ]}
              >
                <Select
                  size={"large"}
                  placeholder={"Séléctionner dans la liste"}
                  style={{ width: "100%" }}
                >
                  {yearsList.map((item, index) => (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"date_end_month"} label={"Mois Fin"}>
                <Select
                  size={"large"}
                  placeholder={"Séléctionner dans la liste"}
                  style={{ width: "100%" }}
                >
                  <Select.Option value={"01"}>Janvier</Select.Option>
                  <Select.Option value={"02"}>Février</Select.Option>
                  <Select.Option value={"03"}>Mars</Select.Option>
                  <Select.Option value={"04"}>Avril</Select.Option>
                  <Select.Option value={"05"}>Mai</Select.Option>
                  <Select.Option value={"06"}>Juin</Select.Option>
                  <Select.Option value={"07"}>Juillet</Select.Option>
                  <Select.Option value={"08"}>Aôut</Select.Option>
                  <Select.Option value={"09"}>Septembre</Select.Option>
                  <Select.Option value={"10"}>Octobre</Select.Option>
                  <Select.Option value={"11"}>Novembre</Select.Option>
                  <Select.Option value={"12"}>Décembre</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"date_end_year"} label={"Année Fin"}>
                <Select
                  size={"large"}
                  placeholder={"Séléctionner dans la liste"}
                  style={{ width: "100%" }}
                >
                  {yearsList.map((item, index) => (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Button
            type={"primary"}
            htmlType={"submit"}
            size="large"
            loading={isLoading}
          >
            Enregistrer vos données
          </Button>
        </Form>
      </div>
    );
  }
}

EducationModal.defaultProps = {
  resourceId: null,
  entityParam: null,
  educationDomains: [],
  educationLevels: [],
  professionalDomains: [],
};

export default EducationModal;
