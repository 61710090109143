import React, { Component } from "react";
import { Divider, message, Upload, Spin } from "antd";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import PageLayout from "../layout/base";
import { uploadResume, getDocuments } from "../../api/candidateService";
import { extractFilename } from "../../utils/strings";
const { Dragger } = Upload;

class ResumeUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentsList: [],
      fileList: [],
      uploadFile: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    document.title = "Uploader votre CV";
    setTimeout(() => this.apiFetch(), 500);
  }

  apiFetch() {
    getDocuments()
      .then((result) => {
        let response = result.data;
        if (response.items) {
          this.setState({ documentsList: response.items });
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {});
  }

  uploadProps() {
    return {
      name: "file",
      multiple: true,
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      beforeUpload: (file, fileList) => this.beforeUpload(file, fileList),
      onChange(info) {
        const { status } = info.file;
        if (status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
  }

  beforeUpload(file, fileList) {
    this.setState({ fileList, uploadFile: file });

    return false;
  }

  onFinish = () => {
    const { uploadFile, fileList } = this.state;
    console.log({ uploadFile });

    if (uploadFile == null && fileList.length == 0) {
      message.error("Veuillez choisir un fichier");
      return;
    }

    let bodyParams = new FormData();
    bodyParams.append("upload_file", uploadFile);

    this.setState({ isLoading: true });
    uploadResume(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });
        let response = result.data;
        if (response.uploaded) {
          message.success("CV uploadé avec succès !");
          this.props.history.push("/account/profile");
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error(
          "Une erreur s'est produite lors du traitement de votre demande"
        );
      });
  };

  render() {
    const { isLoading, fileList, documentsList } = this.state;
    const props = this.uploadProps();
    return (
      <PageLayout>
        <section className="accordion">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="container-detail-box">
                  <h1 className="font-weight-bold">Uploader votre CV</h1>
                  <Divider />
                  {documentsList.length > 0 && (
                    <>
                      <h5 className="font-weight-bold">CV Existant</h5>
                      <ul className="skills">
                        {documentsList.map((item, index) => (
                          <li key={index}>
                            <a href={item.file} target={"_blank"}>
                              <i className="mr-2 fe fe-download" />{" "}
                              {extractFilename(item.file)}
                            </a>
                          </li>
                        ))}
                      </ul>
                      <Divider />
                    </>
                  )}

                  <Spin spinning={isLoading}>
                    <div>
                      <Dragger
                        {...props}
                        accept={".pdf,.doc,.docx"}
                        fileList={fileList}
                        multiple={false}
                      >
                        <p className="ant-upload-drag-icon">
                          <UploadOutlined />
                        </p>
                        <div className="p-4">
                          <p className="ant-upload-text">
                            Cliquez ou faites glisser le fichier vers cette zone
                            pour télécharger
                          </p>
                          <p className="ant-upload-hint">
                            Seuls les fichiers de format PDF & Word (2007,
                            2010+) sont accéptés comme pièces jointes
                          </p>
                        </div>
                      </Dragger>
                      <Divider />

                      <div className="d-flex justify-content-between">
                        <button
                          type={"button"}
                          className="btn btn-success font-weight-bold"
                          onClick={() => this.onFinish()}
                        >
                          Enregistrer le Document
                        </button>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </section>
      </PageLayout>
    );
  }

  handleLogout() {
    sessionStorage.clear();
    this.props.history.push("/account/signin");
  }
}

export default ResumeUpload;
