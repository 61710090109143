import React, { Component } from "react";
import moment from "moment";
import { isMobile } from "react-device-detect";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Drawer,
  message,
  DatePicker,
  Modal,
  Space,
} from "antd";
import SlidingPane from "react-sliding-pane";
import PageLayout from "../layout/base";
import EducationModal from "./eudcationModal";
import ExperienceModal from "./experienceModal";
import { userConnected } from "../../utils/token";
import {
  deleteEntityCache,
  getEntityCache,
  updateUserFields,
} from "../../helpers/cache";
import { COUNTIRES_DATA } from "../../data/countriesList";
import { updateProfile, deleteEntity } from "../../api/candidateService";
import {
  getUsersDomains,
  getEducationDomains,
  getEducationLevels,
  getCompanySectors,
} from "../../api/jobService";

class ResumeIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobId: null,
      jobContent: null,
      jobData: null,
      isLoading: true,
      loadingDomains: true,
      modalEducationOpen: false,
      modalExperienceOpen: false,
      modalRegisterOpen: false,
      modalLoginOpen: false,
      domainsItems: [],
      educationDomainsItems: [],
      companySectorsItems: [],
      educationLevels: [],

      educationsList: getEntityCache(),
      experiencesList: getEntityCache("experience"),

      selectedEducation: null,
      selectedExperience: null,
      selectedEntityID: null,
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (!userConnected()) {
      this.props.history.push("/account/signin");
    }

    document.title = "Modifer le profil";
    setTimeout(() => this.apiFetchData(), 700);
    setTimeout(() => this.autoFill(), 1500);
  }

  autoFill() {
    if (!userConnected()) return;

    let json = sessionStorage.getItem("userFields");
    if (!json) return;
    let userFields = JSON.parse(json);
    const { softwares_and_office, languages } = userFields;

    this.formRef.current.setFieldsValue({
      candidate_city: userFields.address_city,
      candidate_country: userFields.address_country,
      candidate_email: userFields.email_address,
      candidate_name: userFields.family_name,
      candidate_firstname: userFields.first_name,
      candidate_lastname: userFields.last_name,
      candidate_phone: userFields.phone_numbers,
      candidate_sex: userFields.gender_sex,
      candidate_address_line: userFields.address_line1,
      candidate_city: userFields.address_line2,

      candidate_skill_excel: softwares_and_office.excel_skill,
      candidate_skill_powerpoint: softwares_and_office.powerpoint_skill,
      candidate_skill_word: softwares_and_office.word_skill,
      candidate_skill_others: softwares_and_office.others_softwares_skill,
    });

    if (userFields.primary_domain_id != "") {
      let candidate_domains = `${userFields.primary_domain_id}`.split(",");
      this.formRef.current.setFieldsValue({ candidate_domains });
    }

    if (userFields.birth_date != "") {
      let candidate_birthdate = moment(userFields.birth_date);
      this.formRef.current.setFieldsValue({ candidate_birthdate });
    }

    if (languages.languages_local != null) {
      let candidate_languages = `${languages.languages_local}`.split(",");
      this.formRef.current.setFieldsValue({ candidate_languages });
    }

    if (languages.languages_international != null) {
      let candidate_languages_intl = `${languages.languages_international}`.split(
        ","
      );
      this.formRef.current.setFieldsValue({ candidate_languages_intl });
    }

    this.setState({ isLoading: false });
  }

  apiFetchData() {
    getUsersDomains().then((result) => {
      this.setState({ loadingDomains: false });

      let response = result.data;
      if (response.data) {
        this.setState({ domainsItems: response.data });
      } else {
        throw new Error("error");
      }
    });

    getEducationDomains().then((result) => {
      this.setState({ loadingDomains: false });

      let response = result.data;
      if (response.data) {
        this.setState({ educationDomainsItems: response.data });
      } else {
        throw new Error("error");
      }
    });

    getEducationLevels().then((result) => {
      this.setState({ loadingDomains: false });

      let response = result.data;
      if (response.data) {
        this.setState({ educationLevels: response.data });
      } else {
        throw new Error("error");
      }
    });

    getCompanySectors().then((result) => {
      this.setState({ loadingDomains: false });

      let response = result.data;
      if (response.data) {
        this.setState({ companySectorsItems: response.data });
      } else {
        throw new Error("error");
      }
    });
  }

  onFormSubmit(payload, resourceId = null) {
    console.log("onFormSubmit: ", payload);
    let educationsList = getEntityCache();
    this.setState({
      modalEducationOpen: false,
      selectedEducation: null,
      selectedEntityID: null,
      educationsList,
    });
  }

  onFormSubmit1(payload, resourceId = null) {
    console.log("onFormSubmit1: ", payload);
    let experiencesList = getEntityCache("experience");
    this.setState({
      modalExperienceOpen: false,
      selectedExperience: null,
      selectedEntityID: null,
      experiencesList,
    });
  }

  handleDeleteEducation(rowId) {
    let bodyParams = new URLSearchParams();
    bodyParams.append("entity_id", rowId);

    this.setState({ isLoading: true });
    deleteEntity(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.deleted) {
          message.success("Element supprimé avec succès");
          // delete entity from cache
          deleteEntityCache(rowId);
          let educationsList = getEntityCache();
          this.setState({ educationsList });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Erreur survenue lors de la suppression");
      });
  }

  handleDeleteExperience(rowId) {
    let bodyParams = new URLSearchParams();
    bodyParams.append("entity_id", rowId);

    this.setState({ isLoading: true });
    deleteEntity(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.deleted) {
          message.success("Element supprimé avec succès");
          // delete entity from cache
          deleteEntityCache(rowId);
          let experiencesList = getEntityCache("experience");
          this.setState({ experiencesList });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Erreur survenue lors de la suppression");
      });
  }

  handleEditEducation(rowEntity) {
    this.setState({
      modalEducationOpen: true,
      selectedEducation: rowEntity,
      selectedEntityID: rowEntity.id,
    });
  }

  handleEditExperience(rowEntity) {
    this.setState({
      modalExperienceOpen: true,
      selectedExperience: rowEntity,
      selectedEntityID: rowEntity.id,
    });
  }

  onFinish = (values) => {
    console.log("onFinish: ", values);

    const { educationsList, experiencesList, jobId } = this.state;
    const {
      candidate_city,
      candidate_country,
      candidate_address_line,
      candidate_domains,
      candidate_email,
      candidate_firstname,
      candidate_name,
      candidate_languages,
      candidate_languages_intl,
      candidate_lastname,
      candidate_phone,
      candidate_skill_excel,
      candidate_skill_others,
      candidate_skill_powerpoint,
      candidate_skill_word,
      candidate_sex,
      candidate_birthdate,
      estimated_salary,
    } = values;

    let profileData = sessionStorage.getItem("userFields");
    let userFields = JSON.parse(profileData);

    if (educationsList.length == 0) {
      message.error(
        "Veuillez ajouter au moins un Parcours scolaire / Universitaire"
      );
      return;
    }

    let bodyParams = new URLSearchParams();
    bodyParams.append("candidate_reference", userFields.id);
    bodyParams.append("candidate_name", candidate_name);
    bodyParams.append("candidate_firstname", candidate_firstname);
    bodyParams.append("candidate_lastname", candidate_lastname);
    bodyParams.append("candidate_email", candidate_email);
    bodyParams.append("candidate_phone", candidate_phone);
    bodyParams.append("candidate_sex", candidate_sex);
    bodyParams.append("estimated_salary", estimated_salary);
    bodyParams.append(
      "candidate_birthdate",
      candidate_birthdate.format("YYYY-MM-DD")
    );

    bodyParams.append(
      "candidate_languages",
      Array.from(candidate_languages).join(",")
    );
    bodyParams.append(
      "candidate_languages_intl",
      Array.from(candidate_languages_intl).join(",")
    );
    bodyParams.append(
      "candidate_domains",
      Array.from(candidate_domains).join(",")
    );

    bodyParams.append("candidate_skill_others", candidate_skill_others);
    bodyParams.append("candidate_skill_excel", candidate_skill_excel);
    bodyParams.append("candidate_skill_powerpoint", candidate_skill_powerpoint);
    bodyParams.append("candidate_skill_word", candidate_skill_word);

    bodyParams.append("candidate_country", candidate_country);
    bodyParams.append("candidate_city", candidate_city);
    bodyParams.append("candidate_address_line", candidate_address_line);

    bodyParams.append("educations_list", JSON.stringify(educationsList));
    bodyParams.append("experiences_list", JSON.stringify(experiencesList));

    this.setState({ isLoading: true });
    updateProfile(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.edited) {
          updateUserFields(values);
          Modal.success({
            title: "Reussi!",
            okText: "Fermer",
            content: "Votre profil a été modifié avec succès",
            onOk: () => {
              this.props.history.push("/");
            },
            onCancel: () => {
              this.props.history.push("/");
            },
          });
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        console.log("err:", err);
        this.setState({ isLoading: false });
        Modal.error({
          title: "Echec",
          content: "Erreur survenue lors de la mise à jour du profil",
          okText: "Fermer",
          onOk: () => {},
        });
      });
  };

  render() {
    const {
      jobContent,
      jobData,
      isLoading,
      modalEducationOpen,
      modalExperienceOpen,
      modalRegisterOpen,
      modalLoginOpen,
      educationLevels,
      educationDomainsItems,
      domainsItems,
      companySectorsItems,
      selectedEducation,
      selectedExperience,
      selectedEntityID,
    } = this.state;
    console.log("jobData", jobData);

    const isConnected = userConnected();
    return (
      <PageLayout>
        <Spin spinning={isLoading}>
          <section>
            <div className="container">{this.columnLeft()}</div>
          </section>
        </Spin>

        {/** <Drawer
          width={700}
          visible={modalEducationOpen}
          title="Diplôme / Brevet"
          placement="right"
          onClose={() => {
            this.setState({
              modalEducationOpen: false,
              selectedEducation: null,
              selectedEntityID: null,
            });
          }}
          footer={<div></div>}
        >
          <EducationModal
            resourceId={selectedEntityID}
            entityParam={selectedEducation}
            professionalDomains={domainsItems}
            educationLevels={educationLevels}
            educationDomains={educationDomainsItems}
            onFormSubmit={(payload, index) => this.onFormSubmit(payload, index)}
          />
        </Drawer> */}

        {/** 
          <Drawer
            width={700}
            visible={modalExperienceOpen}
            title="Parcours professionnel"
            placement="right"
            onClose={() => {
              this.setState({
                modalExperienceOpen: false,
                selectedExperience: null,
                selectedEntityID: null,
              });
            }}
            footer={<div></div>}
          >
            <ExperienceModal
              resourceId={selectedEntityID}
              entityParam={selectedExperience}
              departementsList={domainsItems}
              companySectors={companySectorsItems}
              onFormSubmit1={(payload, index) =>
                this.onFormSubmit1(payload, index)
              }
            />
          </Drawer>
         */}

        <SlidingPane
          isOpen={modalEducationOpen}
          title="Diplôme / Brevet"
          width={isMobile ? "100%" : "60%"}
          onRequestClose={() => {
            this.setState({
              modalEducationOpen: false,
              selectedEducation: null,
              selectedEntityID: null,
            });
          }}
        >
          <EducationModal
            resourceId={selectedEntityID}
            entityParam={selectedEducation}
            professionalDomains={domainsItems}
            educationLevels={educationLevels}
            educationDomains={educationDomainsItems}
            onFormSubmit={(payload, index) => this.onFormSubmit(payload, index)}
          />
        </SlidingPane>

        <SlidingPane
          width={isMobile ? "100%" : "60%"}
          isOpen={modalExperienceOpen}
          title="Parcours professionnel"
          onRequestClose={() => {
            this.setState({
              modalExperienceOpen: false,
              selectedExperience: null,
              selectedEntityID: null,
            });
          }}
        >
          <ExperienceModal
            resourceId={selectedEntityID}
            entityParam={selectedExperience}
            departementsList={domainsItems}
            companySectors={companySectorsItems}
            onFormSubmit1={(payload, index) =>
              this.onFormSubmit1(payload, index)
            }
          />
        </SlidingPane>
      </PageLayout>
    );
  }

  columnLeft() {
    const {
      loadingDomains,
      domainsItems,
      jobContent,
      educationsList,
      experiencesList,
    } = this.state;
    const isConnected = userConnected();
    const columnsTable1 = [
      {
        title: "Début",
        dataIndex: "date_debut_month",
        key: "date_debut_month",
        width: "15%",
        render: (text, record) => (
          <span className="font-weight-bold">
            {record.date_debut_month}/{record.date_debut_year}
          </span>
        ),
      },
      {
        title: "Institut / Univ.",
        dataIndex: "education_institute",
        key: "name",
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: "Domaine",
        dataIndex: "education_domain_text",
        key: "name",
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        width: "15%",
        render: (text, record, index) => (
          <Space>
            <Button onClick={() => this.handleEditEducation(record)}>
              Modifier.
            </Button>
            <Button danger onClick={() => this.handleDeleteEducation(text)}>
              Supprimer
            </Button>
          </Space>
        ),
      },
    ];
    const columnsTable2 = [
      {
        title: "Début",
        dataIndex: "date_debut_month",
        key: "date_debut_month",
        width: "15%",
        render: (text, record) => (
          <span className="font-weight-bold">
            {record.date_debut_month}/{record.date_debut_year}
          </span>
        ),
      },
      {
        title: "Entreprise/Société",
        dataIndex: "experience_company_name",
        key: "name",
        width: "12%",
      },
      {
        title: "Departement",
        dataIndex: "experience_sector_text",
        key: "name",
        width: "17%",
      },
      {
        title: "Poste",
        dataIndex: "experience_position",
        key: "experience_position",
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        width: "15%",
        render: (text, record, index) => (
          <Space>
            <Button onClick={() => this.handleEditExperience(record)}>
              Modifier.
            </Button>
            <Button danger onClick={() => this.handleDeleteExperience(text)}>
              Supprimer
            </Button>
          </Space>
        ),
      },
    ];
    return (
      <div className="col-sm-12">
        {isConnected && (
          <div className="container-detail-box">
            <div>
              <Form
                ref={this.formRef}
                layout="vertical"
                onFinish={this.onFinish}
              >
                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_name"}
                      label={"Nom de famille"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_lastname"}
                      label={"Post Nom"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_firstname"}
                      label={"Prenom"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_phone"}
                      label={"Téléphone"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_country"}
                      label={"Pays de résidence"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                      >
                        {COUNTIRES_DATA.map((item, index) => (
                          <Select.Option value={item.code}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_city"}
                      label={"Ville"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={"candidate_address_line"}
                      label={"Adresse de résidence"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"candidate_email"}
                      label={"Adresse email"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input size={"large"} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item
                          name={"candidate_sex"}
                          label={"Sexe"}
                          rules={[
                            {
                              required: true,
                              message: "Veuillez compléter ce champs!",
                            },
                          ]}
                        >
                          <Select
                            size={"large"}
                            placeholder={"Séléctionner"}
                            style={{ width: "100%" }}
                          >
                            <Select.Option value={"MALE"}>Homme</Select.Option>
                            <Select.Option value={"FEMALE"}>
                              Femme
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={"candidate_birthdate"}
                          label={"Date de naissance"}
                          rules={[
                            {
                              required: true,
                              message: "Veuillez compléter ce champs!",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={"DD/MM/YYYY"}
                            size={"large"}
                            placeholder={"Séléctionner date"}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name={"candidate_domains"}
                      label={"Domaine(s) de Métier"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        loading={loadingDomains}
                        size={"large"}
                        mode={"multiple"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                        showArrow={true}
                        showSearch={true}
                        onSearch={(val) => console.log("onSearch:", val)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {domainsItems.map((item, index) => (
                          <Select.Option key={index} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h4>Diplôme / Brevet</h4>
                  </div>
                  <Button
                    onClick={() => {
                      if (!isConnected) {
                        this.setState({ modalLoginOpen: true });
                      } else {
                        this.setState({ modalEducationOpen: true });
                      }
                    }}
                  >
                    Ajouter un diplôme
                  </Button>
                </div>
                <Table
                  style={{ marginTop: 10, marginBottom: 20 }}
                  dataSource={educationsList}
                  columns={columnsTable1}
                />
                <Divider />
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h4>Expérience(s) professionnelle(s)</h4>
                  </div>
                  <Button
                    onClick={() => {
                      if (!isConnected) {
                        this.setState({ modalLoginOpen: true });
                      } else {
                        this.setState({ modalExperienceOpen: true });
                      }
                    }}
                  >
                    Ajouter une expérience
                  </Button>
                </div>
                <Table
                  style={{ marginTop: 10, marginBottom: 20 }}
                  dataSource={experiencesList}
                  columns={columnsTable2}
                />
                <Divider />
                <h4>Langues et Autres Compétences</h4>
                <Row gutter={10}>
                  <Col span={24}>
                    <Form.Item
                      name={"candidate_languages_intl"}
                      label={"Langues Internationnales"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        mode={"multiple"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                      >
                        <Select.Option value={"Français"}>
                          Français
                        </Select.Option>
                        <Select.Option value={"Anglais"}>Anglais</Select.Option>
                        <Select.Option value={"Italien"}>Italien</Select.Option>
                        <Select.Option value={"Allemand"}>
                          Allemand
                        </Select.Option>
                        <Select.Option value={"Portugais"}>
                          Portugais
                        </Select.Option>
                        <Select.Option value={"Mandarin"}>
                          Mandarin
                        </Select.Option>
                        <Select.Option value={"Russe"}>Russe</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={"candidate_languages"}
                      label={"Langues Locales"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        mode={"multiple"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                      >
                        <Select.Option value={"Lingala"}>Lingala</Select.Option>
                        <Select.Option value={"Tshiluba"}>
                          Tshiluba
                        </Select.Option>
                        <Select.Option value={"Kikongo"}>Kikongo</Select.Option>
                        <Select.Option value={"Swahili"}>Swahili</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={"candidate_skill_word"}
                      label={"Maitrise Word"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                      >
                        <Select.Option value={"AUCUN"}>AUCUN</Select.Option>
                        <Select.Option value={"DEBUTANT"}>
                          DEBUTANT
                        </Select.Option>
                        <Select.Option value={"MAITRISE"}>
                          MAITRISE
                        </Select.Option>
                        <Select.Option value={"AVANCE"}>AVANCE</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={"candidate_skill_excel"}
                      label={"Maitrise Excel"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                      >
                        <Select.Option value={"AUCUN"}>AUCUN</Select.Option>
                        <Select.Option value={"DEBUTANT"}>
                          DEBUTANT
                        </Select.Option>
                        <Select.Option value={"MAITRISE"}>
                          MAITRISE
                        </Select.Option>
                        <Select.Option value={"AVANCE"}>AVANCE</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={"candidate_skill_powerpoint"}
                      label={"Maitrise PowerPoint"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        placeholder={"Séléctionner dans la liste"}
                        style={{ width: "100%" }}
                      >
                        <Select.Option value={"AUCUN"}>AUCUN</Select.Option>
                        <Select.Option value={"DEBUTANT"}>
                          DEBUTANT
                        </Select.Option>
                        <Select.Option value={"MAITRISE"}>
                          MAITRISE
                        </Select.Option>
                        <Select.Option value={"AVANCE"}>AVANCE</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name={"candidate_skill_others"}
                      label={"Autres logiciels maitrisés"}
                      rules={[
                        {
                          required: true,
                          message: "Veuillez compléter ce champs!",
                        },
                      ]}
                    >
                      <Input.TextArea rows={10} size={"large"} />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <button
                  type={"button"}
                  className="btn btn-success"
                  onClick={() => {
                    this.formRef.current.submit({});
                  }}
                >
                  Enregistrer les modifications
                </button>
              </Form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ResumeIndex;
