import React, { Component } from "react";
import PageLayout from "../layout/base";
import { Divider, Spin } from "antd";
import queryString from "query-string";
import { singleJob } from "../../api/jobService";
import { Link } from "react-router-dom";
import { extractFilename } from "../../utils/strings";

import $ from "jquery";

class JobView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobId: null,
      jobContent: null,
      jobData: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    let parsed = queryString.parse(window.location.search);
    if (parsed.id) {
      this.setState({ jobId: parsed.id }, () => {
        this.apiFetchData();
      });
    }
  }

  apiFetchData() {
    const { jobId } = this.state;
    singleJob(jobId, { t: Date.now() })
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        if (response.id) {
          this.setState(
            { jobContent: response, jobData: response.data },
            () => {
              let content_html = response.content_html;
              $("#content_html").html(content_html);
            }
          );
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { jobContent, jobData, isLoading } = this.state;
    console.log("jobData", jobData);
    return (
      <PageLayout>
        <Spin spinning={isLoading}>
          <section className="inner-header-page">
            <div className="container">
              <div className="col-md-8">
                <div className="left-side-container">
                  <div className="freelance-image">
                    <a href="company-detail.html">
                      <img
                        src={require("../../../assets/img/logos/default.jpg")}
                        className="img-responsive"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="header-details">
                    <h4>{jobContent && jobContent.title}</h4>
                    <p>ITM</p>
                    <ul>
                      <li>
                        <a href="single-company-profile.html">
                          <i className="fa fa-user" />{" "}
                          {jobContent && jobContent.available_position} Poste(s)
                        </a>
                      </li>
                      <li>
                        <div className="verified-action">Vérifié</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 bl-1 br-gary">
                <div className="right-side-detail">
                  {jobContent && (
                    <ul>
                      <li>
                        <span className="detail-info">Durée</span>
                        {jobContent.contrat_duration}
                      </li>
                      <li>
                        <span className="detail-info">Experience</span>
                        {`${jobData.experience_years} ans`}
                      </li>
                      <li>
                        <span className="detail-info">Emplacement</span>
                        {jobContent.locations}
                      </li>
                    </ul>
                  )}

                  <ul className="social-info">
                    <li>
                      <a
                        href="https://www.facebook.com/itmafrica/"
                        target={"_blank"}
                      >
                        <i className="fe fe-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/ItmRdc" target={"_blank"}>
                        <i className="fe fe-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/itm-africa-7526a6162/detail/recent-activity/"
                        target={"_blank"}
                      >
                        <i className="fe fe-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UC9b7Hg1wrog88Qv7PIeDeng"
                        target={"_blank"}
                      >
                        <i className="fe fe-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {jobData && (
            <section>
              <div className="container">{this.columnLeft()}</div>
            </section>
          )}
        </Spin>
      </PageLayout>
    );
  }

  columnLeft() {
    const { jobData, jobContent } = this.state;
    return (
      <div className="col-md-8 col-sm-12">
        <div className="container-detail-box">
          <div className="apply-job-header">
            <h4>{jobContent.title}</h4>
            <a href="#!" className="cl-success">
              <span>
                <i className="fe fe-globe" />
                ITM
              </span>
            </a>
            <span>
              <i className="fe fe-map-pin" />
              {jobContent.locations}
            </span>
          </div>
          <div className="apply-job-detail" id={"content_html"}></div>
          <div className="apply-job-detail">
            <h5>Domaines d'activités</h5>
            <ul className="skills">
              {jobData.user_domains.map((item, index) => (
                <li key={index}>{item.name}</li>
              ))}
            </ul>
          </div>
          <div className="apply-job-detail">
            <h5>Domaines d'études</h5>
            <ul className="skills">
              {jobData.education_domains.map((item, index) => (
                <li key={index}>{item.name}</li>
              ))}
            </ul>
          </div>
          <div className="apply-job-detail">
            <h5>Requirements</h5>
            <ul className="job-requirements">
              <li>
                <span className="font-weight-bold">Expériences</span>
              </li>
              <li>
                <span>Dans le secreur</span>
                {jobData.experience_in_sector && "Fortement récommendé"}
              </li>
              <li>
                <span>Dans le métier choisi</span>{" "}
                {jobData.experience_in_domain && "Fortement récommendé"}
              </li>
              <li>
                <hr />
              </li>
              <li>
                <span className="font-weight-bold">
                  Bureautique &amp; Logiciels
                </span>
              </li>
              <li>
                <span>Office Excel</span>
                {jobData.excel_skill}
              </li>
              <li>
                <span>Office Word</span>
                {jobData.word_skill}
              </li>
              <li>
                <span>Office PowerPoint</span>
                {jobData.powerpoint_skill}
              </li>
              <li>
                <span>Autres</span>
                {jobData.others_softwares_skill}
              </li>
              <li>
                <hr />
              </li>
              <li>
                <span>Niveau d'études</span> {jobData.study_level_name}
              </li>
              <li>
                <span>Langues Intl.</span> {jobData.languages_international}
              </li>
              <li>
                <span>Langues locales</span> {jobData.languages_local}
              </li>
            </ul>
          </div>
          {Array.from(jobData.attachements_list).length > 0 && (
            <>
              <Divider />
              <div className="apply-job-detail">
                <h5>Téléchargez le descriptif</h5>
                <ul className="skills">
                  {jobData.attachements_list.map((item, index) => (
                    <li key={index}>
                      <a href={item} target={"_blank"}>
                        <i className="mr-2 fe fe-paperclip" />{" "}
                        {extractFilename(item)}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
          {!jobContent.expired && (
            <>
              <Divider />
              <Link
                to={`/apply?id=${jobContent.id}`}
                className="btn btn-success"
              >
                Postuler à cette offre
              </Link>
            </>
          )}
          {jobContent.expired && (
            <>
              <Divider />
              <a href={`#`} className="btn btn-outline-danger">
                Cette offre est clôturée
              </a>
            </>
          )}
        </div>
        <div className="job-detail-statistic flex-middle-sm">
          <div className="statistic-item flex-middle">
            <div className="icon text-theme">
              <i className="fe fe-phone-call theme-cl" />
            </div>
            <span className="text">(+243) 82 85 02 119</span>
          </div>
          <div className="statistic-item flex-middle">
            <div className="icon text-theme">
              <i className="fe fe-mail theme-cl" />
            </div>
            <span className="text">contact@itmafrica.com</span>
          </div>
          <div className="statistic-item flex-middle">
            <div className="icon text-theme">
              <i className="fe fe-linkedin theme-cl" />
            </div>
            <span className="text">itmafrica</span>
          </div>
        </div>
        {/* Similar Jobs */}
      </div>
    );
  }
}

export default JobView;
