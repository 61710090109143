const countriesList = [
  {
    key: "5646565454",
    value: "5646565454",
    label: "Afghanistan",
  },
  {
    key: "4646565454",
    value: "4646565454",
    label: "Aland islands",
  },
  {
    key: "5746565454",
    value: "5746565454",
    label: "Albania",
  },
  {
    key: "4746565454",
    value: "4746565454",
    label: "Algeria",
  },
  {
    key: "5657565454",
    value: "5657565454",
    label: "American samoa",
  },
  {
    key: "4657565454",
    value: "4657565454",
    label: "Andorra",
  },
  {
    key: "5757565454",
    value: "5757565454",
    label: "Angola",
  },
  {
    key: "4757565454",
    value: "4757565454",
    label: "Anguilla",
  },
  {
    key: "5647565454",
    value: "5647565454",
    label: "Antarctica",
  },
  {
    key: "4647565454",
    value: "4647565454",
    label: "Antigua and barbuda",
  },
  {
    key: "5747565454",
    value: "5747565454",
    label: "Argentina",
  },
  {
    key: "4747565454",
    value: "4747565454",
    label: "Armenia",
  },
  {
    key: "5656465454",
    value: "5656465454",
    label: "Aruba",
  },
  {
    key: "4656465454",
    value: "4656465454",
    label: "Australia",
  },
  {
    key: "5756465454",
    value: "5756465454",
    label: "Austria",
  },
  {
    key: "4756465454",
    value: "4756465454",
    label: "Azerbaijan",
  },
  {
    key: "5646465454",
    value: "5646465454",
    label: "Bahamas",
  },
  {
    key: "4646465454",
    value: "4646465454",
    label: "Bahrain",
  },
  {
    key: "5746465454",
    value: "5746465454",
    label: "Bangladesh",
  },
  {
    key: "4746465454",
    value: "4746465454",
    label: "Barbados",
  },
  {
    key: "5657465454",
    value: "5657465454",
    label: "Belarus",
  },
  {
    key: "4657465454",
    value: "4657465454",
    label: "Belgium",
  },
  {
    key: "5757465454",
    value: "5757465454",
    label: "Belize",
  },
  {
    key: "4757465454",
    value: "4757465454",
    label: "Benin",
  },
  {
    key: "5647465454",
    value: "5647465454",
    label: "Bermuda",
  },
  {
    key: "4647465454",
    value: "4647465454",
    label: "Bhutan",
  },
  {
    key: "5747465454",
    value: "5747465454",
    label: "Bolivia",
  },
  {
    key: "4747465454",
    value: "4747465454",
    label: "Bosnia and herzegovina",
  },
  {
    key: "5656575454",
    value: "5656575454",
    label: "Botswana",
  },
  {
    key: "4656575454",
    value: "4656575454",
    label: "Bouvet island",
  },
  {
    key: "5756575454",
    value: "5756575454",
    label: "Brazil",
  },
  {
    key: "4756575454",
    value: "4756575454",
    label: "British indian ocean territory",
  },
  {
    key: "5646575454",
    value: "5646575454",
    label: "Brunei darussalam",
  },
  {
    key: "4646575454",
    value: "4646575454",
    label: "Bulgaria",
  },
  {
    key: "5746575454",
    value: "5746575454",
    label: "Burkina faso",
  },
  {
    key: "4746575454",
    value: "4746575454",
    label: "Burundi",
  },
  {
    key: "5657575454",
    value: "5657575454",
    label: "Cambodia",
  },
  {
    key: "4657575454",
    value: "4657575454",
    label: "Cameroon",
  },
  {
    key: "5757575454",
    value: "5757575454",
    label: "Canada",
  },
  {
    key: "4757575454",
    value: "4757575454",
    label: "Cape verde",
  },
  {
    key: "5647575454",
    value: "5647575454",
    label: "Cayman islands",
  },
  {
    key: "4647575454",
    value: "4647575454",
    label: "Central african republic",
  },
  {
    key: "5747575454",
    value: "5747575454",
    label: "Chad",
  },
  {
    key: "4747575454",
    value: "4747575454",
    label: "Chile",
  },
  {
    key: "5656475454",
    value: "5656475454",
    label: "China",
  },
  {
    key: "4656475454",
    value: "4656475454",
    label: "Christmas island",
  },
  {
    key: "5756475454",
    value: "5756475454",
    label: "Cocos (keeling) islands",
  },
  {
    key: "4756475454",
    value: "4756475454",
    label: "Colombia",
  },
  {
    key: "5646475454",
    value: "5646475454",
    label: "Comoros",
  },
  {
    key: "4646475454",
    value: "4646475454",
    label: "Congo",
  },
  {
    key: "4746475454",
    value: "4746475454",
    label: "Cook islands",
  },
  {
    key: "5657475454",
    value: "5657475454",
    label: "Costa rica",
  },
  {
    key: "4646474554",
    value: "4646474554",
    label: "Cote d'ivoire",
  },
  {
    key: "4657475454",
    value: "4657475454",
    label: "Croatia",
  },
  {
    key: "5757475454",
    value: "5757475454",
    label: "Cuba",
  },
  {
    key: "4757475454",
    value: "4757475454",
    label: "Cyprus",
  },
  {
    key: "5647475454",
    value: "5647475454",
    label: "Czech republic",
  },
  {
    key: "4647475454",
    value: "4647475454",
    label: "Denmark",
  },
  {
    key: "5747475454",
    value: "5747475454",
    label: "Djibouti",
  },
  {
    key: "4747475454",
    value: "4747475454",
    label: "Dominica",
  },
  {
    key: "5656564454",
    value: "5656564454",
    label: "Dominican republic",
  },
  {
    key: "4656564454",
    value: "4656564454",
    label: "Ecuador",
  },
  {
    key: "5756564454",
    value: "5756564454",
    label: "Egypt",
  },
  {
    key: "4756564454",
    value: "4756564454",
    label: "El salvador",
  },
  {
    key: "5646564454",
    value: "5646564454",
    label: "Equatorial guinea",
  },
  {
    key: "4646564454",
    value: "4646564454",
    label: "Eritrea",
  },
  {
    key: "5746564454",
    value: "5746564454",
    label: "Estonia",
  },
  {
    key: "4746564454",
    value: "4746564454",
    label: "Ethiopia",
  },
  {
    key: "5657564454",
    value: "5657564454",
    label: "Falkland islands (malvinas)",
  },
  {
    key: "4657564454",
    value: "4657564454",
    label: "Faroe islands",
  },
  {
    key: "5757564454",
    value: "5757564454",
    label: "Fiji",
  },
  {
    key: "4757564454",
    value: "4757564454",
    label: "Finland",
  },
  {
    key: "5647564454",
    value: "5647564454",
    label: "France",
  },
  {
    key: "4647564454",
    value: "4647564454",
    label: "French guiana",
  },
  {
    key: "5747564454",
    value: "5747564454",
    label: "French polynesia",
  },
  {
    key: "4747564454",
    value: "4747564454",
    label: "French southern territories",
  },
  {
    key: "5656464454",
    value: "5656464454",
    label: "Gabon",
  },
  {
    key: "4656464454",
    value: "4656464454",
    label: "Gambia",
  },
  {
    key: "5756464454",
    value: "5756464454",
    label: "Georgia",
  },
  {
    key: "4756464454",
    value: "4756464454",
    label: "Germany",
  },
  {
    key: "5646464454",
    value: "5646464454",
    label: "Ghana",
  },
  {
    key: "4646464454",
    value: "4646464454",
    label: "Gibraltar",
  },
  {
    key: "5746464454",
    value: "5746464454",
    label: "Greece",
  },
  {
    key: "4746464454",
    value: "4746464454",
    label: "Greenland",
  },
  {
    key: "5657464454",
    value: "5657464454",
    label: "Grenada",
  },
  {
    key: "4657464454",
    value: "4657464454",
    label: "Guadeloupe",
  },
  {
    key: "5757464454",
    value: "5757464454",
    label: "Guam",
  },
  {
    key: "4757464454",
    value: "4757464454",
    label: "Guatemala",
  },
  {
    key: "5647464454",
    value: "5647464454",
    label: "Guernsey",
  },
  {
    key: "4647464454",
    value: "4647464454",
    label: "Guinea",
  },
  {
    key: "5747464454",
    value: "5747464454",
    label: "Guinea-bissau",
  },
  {
    key: "4747464454",
    value: "4747464454",
    label: "Guyana",
  },
  {
    key: "5656574454",
    value: "5656574454",
    label: "Haiti",
  },
  {
    key: "4656574454",
    value: "4656574454",
    label: "Heard island and mcdonald islands",
  },
  {
    key: "5756574454",
    value: "5756574454",
    label: "Honduras",
  },
  {
    key: "4756574454",
    value: "4756574454",
    label: "Hong kong",
  },
  {
    key: "5646574454",
    value: "5646574454",
    label: "Hungary",
  },
  {
    key: "4646574454",
    value: "4646574454",
    label: "Iceland",
  },
  {
    key: "5746574454",
    value: "5746574454",
    label: "India",
  },
  {
    key: "4746574454",
    value: "4746574454",
    label: "Indonesia",
  },
  {
    key: "5657574454",
    value: "5657574454",
    label: "Iran, islamic republic of",
  },
  {
    key: "4657574454",
    value: "4657574454",
    label: "Iraq",
  },
  {
    key: "5757574454",
    value: "5757574454",
    label: "Ireland",
  },
  {
    key: "4757574454",
    value: "4757574454",
    label: "Isle of man",
  },
  {
    key: "5647574454",
    value: "5647574454",
    label: "Israel",
  },
  {
    key: "4647574454",
    value: "4647574454",
    label: "Italy",
  },
  {
    key: "5747574454",
    value: "5747574454",
    label: "Jamaica",
  },
  {
    key: "4747574454",
    value: "4747574454",
    label: "Japan",
  },
  {
    key: "5656474454",
    value: "5656474454",
    label: "Jersey",
  },
  {
    key: "4656474454",
    value: "4656474454",
    label: "Jordan",
  },
  {
    key: "5756474454",
    value: "5756474454",
    label: "Kazakhstan",
  },
  {
    key: "4756474454",
    value: "4756474454",
    label: "Kenya",
  },
  {
    key: "5646474454",
    value: "5646474454",
    label: "Kiribati",
  },
  {
    key: "4646474454",
    value: "4646474454",
    label: "Korea, republic of",
  },
  {
    key: "5746474454",
    value: "5746474454",
    label: "Kuwait",
  },
  {
    key: "4746474454",
    value: "4746474454",
    label: "Kyrgyzstan",
  },
  {
    key: "5657474454",
    value: "5657474454",
    label: "Latvia",
  },
  {
    key: "4657474454",
    value: "4657474454",
    label: "Lebanon",
  },
  {
    key: "5757474454",
    value: "5757474454",
    label: "Lesotho",
  },
  {
    key: "4757474454",
    value: "4757474454",
    label: "Liberia",
  },
  {
    key: "5647474454",
    value: "5647474454",
    label: "Libyan arab jamahiriya",
  },
  {
    key: "4647474454",
    value: "4647474454",
    label: "Liechtenstein",
  },
  {
    key: "5747474454",
    value: "5747474454",
    label: "Lithuania",
  },
  {
    key: "4747474454",
    value: "4747474454",
    label: "Luxembourg",
  },
  {
    key: "5656565554",
    value: "5656565554",
    label: "Macao",
  },
  {
    key: "4656565554",
    value: "4656565554",
    label: "Macedoni, the former yugoslav republic of",
  },
  {
    key: "5756565554",
    value: "5756565554",
    label: "Madagascar",
  },
  {
    key: "4756565554",
    value: "4756565554",
    label: "Malawi",
  },
  {
    key: "5646565554",
    value: "5646565554",
    label: "Malaysia",
  },
  {
    key: "4646565554",
    value: "4646565554",
    label: "Maldives",
  },
  {
    key: "5746565554",
    value: "5746565554",
    label: "Mali",
  },
  {
    key: "4746565554",
    value: "4746565554",
    label: "Malta",
  },
  {
    key: "5657565554",
    value: "5657565554",
    label: "Marshall islands",
  },
  {
    key: "4657565554",
    value: "4657565554",
    label: "Martinique",
  },
  {
    key: "5757565554",
    value: "5757565554",
    label: "Mauritania",
  },
  {
    key: "4757565554",
    value: "4757565554",
    label: "Mauritius",
  },
  {
    key: "5647565554",
    value: "5647565554",
    label: "Mayotte",
  },
  {
    key: "4647565554",
    value: "4647565554",
    label: "Mexico",
  },
  {
    key: "5747565554",
    value: "5747565554",
    label: "Micronesia, federated states of",
  },
  {
    key: "4747565554",
    value: "4747565554",
    label: "Moldova, republic of",
  },
  {
    key: "5656465554",
    value: "5656465554",
    label: "Monaco",
  },
  {
    key: "4656465554",
    value: "4656465554",
    label: "Mongolia",
  },
  {
    key: "5756465554",
    value: "5756465554",
    label: "Montenegro",
  },
  {
    key: "4756465554",
    value: "4756465554",
    label: "Montserrat",
  },
  {
    key: "5646465554",
    value: "5646465554",
    label: "Morocco",
  },
  {
    key: "4646465554",
    value: "4646465554",
    label: "Mozambique",
  },
  {
    key: "5746465554",
    value: "5746465554",
    label: "Myanmar",
  },
  {
    key: "4746465554",
    value: "4746465554",
    label: "Namibia",
  },
  {
    key: "5657465554",
    value: "5657465554",
    label: "Nauru",
  },
  {
    key: "4657465554",
    value: "4657465554",
    label: "Nepal",
  },
  {
    key: "5757465554",
    value: "5757465554",
    label: "Netherlands",
  },
  {
    key: "4757465554",
    value: "4757465554",
    label: "Netherlands antilles",
  },
  {
    key: "5647465554",
    value: "5647465554",
    label: "New caledonia",
  },
  {
    key: "4647465554",
    value: "4647465554",
    label: "New zealand",
  },
  {
    key: "5747465554",
    value: "5747465554",
    label: "Nicaragua",
  },
  {
    key: "4747465554",
    value: "4747465554",
    label: "Niger",
  },
  {
    key: "5656575554",
    value: "5656575554",
    label: "Nigeria",
  },
  {
    key: "4656575554",
    value: "4656575554",
    label: "Niue",
  },
  {
    key: "5756575554",
    value: "5756575554",
    label: "Norfolk island",
  },
  {
    key: "4756575554",
    value: "4756575554",
    label: "Northern mariana islands",
  },
  {
    key: "5646575554",
    value: "5646575554",
    label: "Norway",
  },
  {
    key: "4646575554",
    value: "4646575554",
    label: "Oman",
  },
  {
    key: "5746575554",
    value: "5746575554",
    label: "Pakistan",
  },
  {
    key: "4746575554",
    value: "4746575554",
    label: "Palau",
  },
  {
    key: "5657575554",
    value: "5657575554",
    label: "Palestinian territory, occupied",
  },
  {
    key: "4657575554",
    value: "4657575554",
    label: "Panama",
  },
  {
    key: "5757575554",
    value: "5757575554",
    label: "Papua new guinea",
  },
  {
    key: "4757575554",
    value: "4757575554",
    label: "Paraguay",
  },
  {
    key: "5647575554",
    value: "5647575554",
    label: "Peru",
  },
  {
    key: "4647575554",
    value: "4647575554",
    label: "Philippines",
  },
  {
    key: "5747575554",
    value: "5747575554",
    label: "Pitcairn",
  },
  {
    key: "4747575554",
    value: "4747575554",
    label: "Poland",
  },
  {
    key: "5656475554",
    value: "5656475554",
    label: "Portugal",
  },
  {
    key: "4656475554",
    value: "4656475554",
    label: "Puerto rico",
  },
  {
    key: "5756475554",
    value: "5756475554",
    label: "Qatar",
  },
  {
    key: "5746475454",
    value: "5746475454",
    label: "R.d. congo",
  },
  {
    key: "4756475554",
    value: "4756475554",
    label: "Reunion",
  },
  {
    key: "5646475554",
    value: "5646475554",
    label: "Romania",
  },
  {
    key: "4646475554",
    value: "4646475554",
    label: "Russian federation",
  },
  {
    key: "5746475554",
    value: "5746475554",
    label: "Rwanda",
  },
  {
    key: "4746475554",
    value: "4746475554",
    label: "Saint helena",
  },
  {
    key: "5657475554",
    value: "5657475554",
    label: "Saint kitts and nevis",
  },
  {
    key: "4657475554",
    value: "4657475554",
    label: "Saint lucia",
  },
  {
    key: "5757475554",
    value: "5757475554",
    label: "Saint pierre and miquelon",
  },
  {
    key: "4757475554",
    value: "4757475554",
    label: "Saint vincent and the grenadines",
  },
  {
    key: "5647475554",
    value: "5647475554",
    label: "Samoa",
  },
  {
    key: "4647475554",
    value: "4647475554",
    label: "San marino",
  },
  {
    key: "5747475554",
    value: "5747475554",
    label: "Sao tome and principe",
  },
  {
    key: "4747475554",
    value: "4747475554",
    label: "Saudi arabia",
  },
  {
    key: "5656564554",
    value: "5656564554",
    label: "Senegal",
  },
  {
    key: "4656564554",
    value: "4656564554",
    label: "Serbia",
  },
  {
    key: "5756564554",
    value: "5756564554",
    label: "Seychelles",
  },
  {
    key: "4756564554",
    value: "4756564554",
    label: "Sierra leone",
  },
  {
    key: "5646564554",
    value: "5646564554",
    label: "Singapore",
  },
  {
    key: "4646564554",
    value: "4646564554",
    label: "Slovakia",
  },
  {
    key: "5746564554",
    value: "5746564554",
    label: "Slovenia",
  },
  {
    key: "4746564554",
    value: "4746564554",
    label: "Solomon islands",
  },
  {
    key: "5657564554",
    value: "5657564554",
    label: "Somalia",
  },
  {
    key: "4657564554",
    value: "4657564554",
    label: "South africa",
  },
  {
    key: "5757564554",
    value: "5757564554",
    label: "South georgia and the south sandwich islands",
  },
  {
    key: "4757564554",
    value: "4757564554",
    label: "Spain",
  },
  {
    key: "5647564554",
    value: "5647564554",
    label: "Sri lanka",
  },
  {
    key: "4647564554",
    value: "4647564554",
    label: "Sudan",
  },
  {
    key: "5747564554",
    value: "5747564554",
    label: "Suriname",
  },
  {
    key: "4747564554",
    value: "4747564554",
    label: "Svalbard and jan mayen",
  },
  {
    key: "5656464554",
    value: "5656464554",
    label: "Swaziland",
  },
  {
    key: "4656464554",
    value: "4656464554",
    label: "Sweden",
  },
  {
    key: "5756464554",
    value: "5756464554",
    label: "Switzerland",
  },
  {
    key: "4756464554",
    value: "4756464554",
    label: "Syrian arab republic",
  },
  {
    key: "5646464554",
    value: "5646464554",
    label: "Taiwan, province of china",
  },
  {
    key: "4646464554",
    value: "4646464554",
    label: "Tajikistan",
  },
  {
    key: "5746464554",
    value: "5746464554",
    label: "Tanzania, united republic of",
  },
  {
    key: "4746464554",
    value: "4746464554",
    label: "Thailand",
  },
  {
    key: "5657464554",
    value: "5657464554",
    label: "Timor-leste",
  },
  {
    key: "4657464554",
    value: "4657464554",
    label: "Togo",
  },
  {
    key: "5757464554",
    value: "5757464554",
    label: "Tokelau",
  },
  {
    key: "4757464554",
    value: "4757464554",
    label: "Tonga",
  },
  {
    key: "5647464554",
    value: "5647464554",
    label: "Trinidad and tobago",
  },
  {
    key: "4647464554",
    value: "4647464554",
    label: "Tunisia",
  },
  {
    key: "5747464554",
    value: "5747464554",
    label: "Turkey",
  },
  {
    key: "4747464554",
    value: "4747464554",
    label: "Turkmenistan",
  },
  {
    key: "5656574554",
    value: "5656574554",
    label: "Turks and caicos islands",
  },
  {
    key: "4656574554",
    value: "4656574554",
    label: "Tuvalu",
  },
  {
    key: "5756574554",
    value: "5756574554",
    label: "Uganda",
  },
  {
    key: "4756574554",
    value: "4756574554",
    label: "Ukraine",
  },
  {
    key: "5646574554",
    value: "5646574554",
    label: "United arab emirates",
  },
  {
    key: "4646574554",
    value: "4646574554",
    label: "United kingdom",
  },
  {
    key: "5746574554",
    value: "5746574554",
    label: "United states",
  },
  {
    key: "4746574554",
    value: "4746574554",
    label: "United states minor outlying islands",
  },
  {
    key: "5657574554",
    value: "5657574554",
    label: "Uruguay",
  },
  {
    key: "4657574554",
    value: "4657574554",
    label: "Uzbekistan",
  },
  {
    key: "5757574554",
    value: "5757574554",
    label: "Vanuatu",
  },
  {
    key: "4757574554",
    value: "4757574554",
    label: "Vatican city state (holy see)",
  },
  {
    key: "5647574554",
    value: "5647574554",
    label: "Venezuela",
  },
  {
    key: "4647574554",
    value: "4647574554",
    label: "Viet nam",
  },
  {
    key: "5747574554",
    value: "5747574554",
    label: "Virgin islands, british",
  },
  {
    key: "4747574554",
    value: "4747574554",
    label: "Virgin islands, u.s.",
  },
  {
    key: "5656474554",
    value: "5656474554",
    label: "Wallis and futuna",
  },
  {
    key: "4656474554",
    value: "4656474554",
    label: "Western sahara",
  },
  {
    key: "5756474554",
    value: "5756474554",
    label: "Yemen",
  },
  {
    key: "4756474554",
    value: "4756474554",
    label: "Zambia",
  },
  {
    key: "5646474554",
    value: "5646474554",
    label: "Zimbabwe",
  },
];
export default countriesList;
