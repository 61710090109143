import React, { Component } from "react";
import { Col, Divider, Form, Input, Spin, Row, message } from "antd";
import queryString from "query-string";
import { passwordRecoverReset } from "../../api/candidateService";
import PageLayout from "../layout/base";
import "../../../assets/css/login.css";

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      userName: "admin",
      userPassword: "",
      visiblePage: "LOGIN",
    };
  }

  componentDidMount() {
    document.title = "Récuperer le mot de passe";
    let parsed = queryString.parse(window.location.search);
    const { token } = parsed;
    if (token) {
      this.setState({ verification_token: token });
    } else {
      this.setState({
        verification_token: window.sessionStorage.getItem(
          "verificationRequestToken"
        ),
      });
    }
  }

  onFinish = (values) => {
    const { verification_token } = this.state;

    console.log("values:", values);
    const { password_new, password_new_confirm } = values;

    if (password_new !== password_new_confirm) {
      message.info("Les mots de passe ne correspondent pas !");
      return;
    }

    let bodyParams = new URLSearchParams();
    bodyParams.append("password_new", password_new);
    bodyParams.append("password_new_confirm", password_new_confirm);
    bodyParams.append("verification_token", verification_token);

    this.setState({ isLoading: true });
    passwordRecoverReset(bodyParams)
      .then((result) => {
        this.setState({ isLoading: false });

        let response = result.data;
        const { updated } = response;

        if (updated) {
          window.sessionStorage.clear();
          message.success("Mot de passe modifié avec succès !");

          this.props.history.push(`/account/signin`);
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error("Impossible de modifier le mot de passe");
      });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <PageLayout>
        <main id={"content"} role={"main"} className={"site-wrapper-reveal"}>
          <section
            className={"bg-cover min-vh-100"}
            style={{
              backgroundColor: "#E7EBEF",
            }}
          >
            <div className="d-flex justify-content-center">
              <div className="page-content">
                <div className="login-card">
                  <div className="py-10">
                    <h2 className={"font-weight-bold display-2"}>
                      Créez un nouveau mot de passe
                    </h2>
                    <Divider />
                    <Spin spinning={isLoading}>
                      <Form layout={"vertical"} onFinish={this.onFinish}>
                        <Row gutter={12}>
                          <Col span={24}>
                            <Form.Item
                              label={"Nouveau mot de passe"}
                              name={"password_new"}
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez compléter ce champs!",
                                },
                              ]}
                            >
                              <Input
                                size={"large"}
                                placeholder={"Entrez le nouveau mot de passe"}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label={"Confirmer le mot de passe"}
                              name={"password_new_confirm"}
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez compléter ce champs!",
                                },
                              ]}
                            >
                              <Input
                                size={"large"}
                                placeholder={"Entrez le nouveau mot de passe"}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />

                        <div className="d-flex justify-content-between">
                          <button
                            type={"submit"}
                            className="btn btn-block btn-success font-weight-bold"
                          >
                            Modifier votre mot de passe
                          </button>
                        </div>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
              {/* / .row */}
            </div>
            {/* / .container */}
          </section>
        </main>
      </PageLayout>
    );
  }
}

export default PasswordReset;
