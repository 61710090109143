export const saveToEntityCache = (data) => {
  const { id } = data;
  let array = [];
  let cache = sessionStorage.getItem("candidate_entities");
  if (cache != null) {
    array = JSON.parse(cache);
    let rowIndex = array.findIndex((elt) => elt.id == id);
    if (rowIndex > -1) {
      array[rowIndex] = { ...array[rowIndex], ...data };
      console.group("saveToEntityCache");
      console.log("saveToEntityCache [data]: ", data);
      console.log("saveToEntityCache [array]: ", array[rowIndex]);
      console.groupEnd("saveToEntityCache");
    } else {
      array.push(data);
    }
  } else {
    array.push(data);
  }

  sessionStorage.setItem("candidate_entities", JSON.stringify(array));
};

export const deleteEntityCache = (itemId, type = "education") => {
  let array = [];
  let cache = sessionStorage.getItem("candidate_entities");
  if (cache != null) {
    let data = JSON.parse(cache);
    array = Array.from(data).filter((it) => it.id != itemId);
    sessionStorage.setItem("candidate_entities", JSON.stringify(array));
  }
};

export const getEntityCache = (type = "education") => {
  let array = [];
  let cache = sessionStorage.getItem("candidate_entities");
  if (cache != null) {
    let data = JSON.parse(cache);
    array = Array.from(data).filter((it) => it.type == type);
  }

  return array;
};

export const updateUserFields = (values) => {
  const {
    candidate_city,
    candidate_country,
    candidate_address_line,
    candidate_domains,
    candidate_email,
    candidate_firstname,
    candidate_name,
    candidate_languages,
    candidate_languages_intl,
    candidate_lastname,
    candidate_phone,
    candidate_skill_excel,
    candidate_skill_others,
    candidate_skill_powerpoint,
    candidate_skill_word,
    candidate_sex,
    candidate_birthdate,
    estimated_salary,
  } = values;

  /** about_bio: ""
: ""
: "CD"
: "Av du louvre 210"
: "Kinshasa"
: ""
: "1990-02-17"
: ""
: "Eric Mandala"
: "user1@example.com"
entities: [{contact_email_address: "hams@fucking.dev", contact_fullname: "Hams Walden",…},…]
: "Mandala"
: "Eric"
: "MALE"
id: "candidate-7a24d2afb8c3d452393365b80b2f46d0"
: {languages_local: "Lingala,Tshiluba", languages_international: "Français,Anglais"}
: "Mandala"
: ""
: "2300001901"
: "category-4656565454"
: {excel_skill: "AUCUN", word_skill: "AUCUN", powerpoint_skill: "AUCUN",…} */

  let cache = sessionStorage.getItem("userFields");
  let userFields = JSON.parse(cache);
  if (userFields) {
    userFields["address_city"] = candidate_city;
    userFields["address_country"] = candidate_country;
    userFields["address_line1"] = candidate_address_line;
    userFields["birth_date"] = candidate_birthdate;
    // userFields["birth_place"] = candidate_city;
    userFields["email_address"] = candidate_email;
    userFields["family_name"] = candidate_name;
    userFields["last_name"] = candidate_lastname;
    userFields["first_name"] = candidate_firstname;
    userFields["gender_sex"] = candidate_sex;
    userFields["languages"] = {
      languages_local: candidate_languages,
      languages_international: candidate_languages_intl,
    };
    userFields["nationality"] = candidate_city;
    userFields["phone_numbers"] = candidate_phone;
    userFields["primary_domain_id"] = candidate_domains;
    userFields["softwares_and_office"] = {
      excel_skill: candidate_skill_excel,
      others_softwares_skill: candidate_skill_others,
      powerpoint_skill: candidate_skill_powerpoint,
      word_skill: candidate_skill_word,
    };
    console.log("userFields:", userFields);
    sessionStorage.setItem("userFields", JSON.stringify(userFields));
  }
};
